import { Dimensions, Platform } from 'react-native';
import styled from '../../utils/style/styled';
const { height } = Dimensions.get('window');

const SafeView = styled.SafeAreaView`
  flex: 1;
  background-color: #fff;
`;

const CloseContainer = styled.TouchableOpacity`
  align-items: flex-end;
  margin-right: 26px;
  margin-top: ${Platform.OS !== 'ios' ? (height > 810 ? 60 : 40) : 0}px;
`;

const SectionContainer = styled.View`
  background-color: #fff;
  margin-vertical: 15px;
  margin-horizontal: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FullImage = styled.Image`
  width: ${80 * 4}px;
  height: ${98 * 4}px;
  margin-left: 0;
  margin-top: -8px;
`;

const Image = styled.Image`
  width: 80px;
  height: 98px;
  margin-left: -20px;
  margin-top: -8px;
`;

const SectionTitle = styled.Text`
  font-family: Verdana;
  font-size: 13px;
  color: #929191;
`;
const SectionRow = styled.View`
  flex-direction: row;
`;

const RowTextLeft = styled.Text`
  font-family: Verdana;
  font-size: 22px;
  font-weight: bold;
  color: #494949;
  margin-right: 6px;
`;

const RowTextRight = styled.Text`
  font-family: Verdana;
  font-size: 12px;
  color: #a8a8a8;
`;

const SectionHeaderText = styled.Text`
  color: #414142;
  padding-left: 19px;
  padding-vertical: 7px;
  font-family: Verdana;
  font-size: 13px;
  background-color: #f4f4f4;
`;

const TextError = styled.Text`
  margin-horizontal: 30px;
  text-align: center;
  color: #a8a8a8;
  font-family: Verdana;
  font-size: 12px;
`;

const Loading = styled.ActivityIndicator`
  margin-bottom: 10px;
`;

export {
  Image,
  FullImage,
  SafeView,
  Loading,
  TextError,
  SectionHeaderText,
  SectionRow,
  RowTextRight,
  RowTextLeft,
  SectionTitle,
  CloseContainer,
  SectionContainer
};
