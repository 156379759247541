import { types } from 'mobx-state-tree';

export const User = types.model('User', {
  _id: types.identifier,
  clientId: types.string,
  firstName: types.string,
  lastName: types.string,
  email: types.string,
  name: types.optional(types.string, '')
});
