import * as React from 'react';
import { Linking, StyleSheet, TouchableOpacity } from 'react-native';
import MailIcon from '../../../assets/Mail';
import X from '../../../assets/X';
import { useStores } from '../../state/stores/Root/RootStoreContext';
import { ButtonText, Title } from '../Login/style';
import { Button } from '../ProfileTab/style';
import { ButtonContainer, Container, Label, SafeView, TitleContainer } from './style';

const ChangeEmail: React.FunctionComponent = props => {
  const rootStore = useStores();

  return (
    <SafeView>
      <Container>
        <TitleContainer>
          <Title>{rootStore.language.translate.MOBILE.changeEmailTitle}</Title>
          <TouchableOpacity
            onPress={() => {
              props.navigation.goBack();
            }}
          >
            <X />
          </TouchableOpacity>
        </TitleContainer>

        <Label>{rootStore.language.translate.MOBILE.changeEmailText}</Label>
        <ButtonContainer>
          <MailIcon style={styles.mailIcon} />
          <Button
            onPress={() => {
              Linking.openURL(`mailto:${rootStore.language.translate.MOBILE.infoEmail.toLowerCase()}`);
            }}
          >
            <ButtonText>{rootStore.language.translate.MOBILE.infoEmail}</ButtonText>
          </Button>
        </ButtonContainer>
      </Container>
    </SafeView>
  );
};

const styles = StyleSheet.create({
  mailIcon: { marginRight: 20 }
});

export default ChangeEmail;
