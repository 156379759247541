import gql from 'graphql-tag';
import { flow, types } from 'mobx-state-tree';
import { URL } from '../../../../../config/config';
import { client } from '../../../config/apollo/client';
import { LangModel } from '../../models/Lang';
// import { useStores } from '../Root/RootStoreContext';
import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const GET_LANGUAGES = gql`
  query getLanguages {
    languages(criteria: {}) {
      items {
        _id
        key
        name
      }
    }
  }
`;

const getLocaleUrl = lang => `${URL}${lang}/locale`;

export const LanguageStore: any = types
  .model('LanguageStore', {
    languages: types.optional(types.array(LangModel), () => [{ _id: '1', name: 'English', key: 'en' }]),
    language: types.optional(types.string, 'en'),
    languageData: types.maybe(types.string),
    state: types.maybe(types.enumeration(['pending', 'done', 'error']))
  })
  .actions(self => {
    // const rootStore = useStores();
    const fetchLanguages = flow(function*() {
      self.state = 'pending';
      try {
        const res = yield client.query({ query: GET_LANGUAGES });
        if (res) {
          self.languages = res.data.languages.items;
        }
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        console.log('error fetch languages', error);
        if (JSON.parse(JSON.stringify(error)).message.includes('JSON Parse error: Unexpected token:')) {
          // Means that server url was changed (from demo to prod or from prod to demo)
          // Need to delete token and logged out user
          if (Platform.OS === 'web') {
            AsyncStorage.removeItem('token');
          } else {
            SecureStore.deleteItemAsync('token');
          }
          // rootStore.user.set(null);
        }
      }

      return self.languageData;
    });
    const fetchLanguage = flow(function*() {
      self.state = 'pending';
      try {
        const data = yield fetch(getLocaleUrl(self.language), {
          method: 'GET'
        });
        const res = yield data.json();
        self.languageData = JSON.stringify(res.locale.data);
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        console.log('error');
      }

      return self.languageData;
    });
    const setLanguage = (lang: string) => {
      self.language = lang;
      fetchLanguage();
    };

    return {
      fetchLanguage,
      fetchLanguages,
      setLanguage,
      afterCreate() {
        fetchLanguages();
        fetchLanguage();
      }
    };
  })
  .views(self => ({
    get translate() {
      return JSON.parse(self.languageData ? self.languageData : '');
    },
    get string() {
      const isSetLang = lang => {
        return lang.key === self.language;
      };
      const setLangObj = self.languages.find(isSetLang);
      if (setLangObj) {
        return setLangObj.name;
      }
    },
    get list() {
      return self.languages;
    }
  }));
