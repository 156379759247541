import { ApolloLink, InMemoryCache } from 'apollo-boost';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import * as SecureStore from 'expo-secure-store';
import { GRAPHQL_URL } from '../../../../config/config';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import { onError } from 'apollo-link-error';
// import * as storage from '../../utils/storage';
// import { LoggingLink } from 'apollo-logger';

const httpLink = createHttpLink({
  uri: GRAPHQL_URL
});
// const logOptions = { logger: console.log };

// const responseLogger = new ApolloLink((operation, forward) => {
//   return forward(operation).map(result => {
//     console.info(operation.getContext());
//     return result;
//   });
// });

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  var data = null;
  if (Platform.OS === 'web') {
    data = await AsyncStorage.getItem('token');
  } else {
    data = await SecureStore.getItemAsync('token');
  }

  return {
    headers: {
      ...headers,
      authorization: data ? `Bearer ${data}` : ''
    }
  };
});

// const error = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors) {
//     graphQLErrors.forEach(({ message, locations, path, code }) => {
//       console.log(`[GraphQL error]: Message: ${message}, Location}: ${locations}, Path: ${path}, Code: ${code}`);
//       // Find a better way to logout user than:
//       // const logout = async () => {
//       //   SecureStore.deleteItemAsync('token');
//       //   storage.remove('root');
//       // };
//     });
//   }
//   if (networkError) console.log(`[Network error]: ${networkError}`);
// });

export const client = new ApolloClient({
  link: ApolloLink.from([
    // new LoggingLink(logOptions),
    //  responseLogger,
    authLink
      // .concat(error)
      .concat(httpLink)
  ]),
  cache: new InMemoryCache()
});
