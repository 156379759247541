import * as React from 'react';
import { Container, InputContainer, Label, Text } from './style';

export interface DisabledInputProps {
  label: String;
  value: String;
}

export function DisabledInput(props: DisabledInputProps) {
  return (
    <Container>
      <Label>{props.label}</Label>

      <InputContainer>
        <Text>{props.value}</Text>
      </InputContainer>
    </Container>
  );
}
