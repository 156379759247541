import * as React from 'react';
import { Dimensions, Image, Platform, StyleSheet, View } from 'react-native';
import VirbacLogo from '../../../assets/VirbacLogo';

const { width } = Dimensions.get('window');

export interface HeaderProps {}

export function Header(props: HeaderProps) {
  return (
    <View style={[styles.container, { top: props.withImage ? 0 : 65 }]}>
      {props.withImage ? (
        <Image
          resizeMode="cover"
          style={{ alignSelf: 'flex-start', width }}
          source={require('../../../assets/dog.png')}
        />
      ) : (
        <VirbacLogo />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    alignSelf: 'center',
    backgroundColor: '#004D97',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: Platform.OS === 'android' ? 30 : 0
  }
});
