import { Ionicons } from '@expo/vector-icons';
import { setTimeout } from 'optimism';
import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { BannerContainer, BannerText, Loading } from './style';

export interface BannerProps {
  visible: boolean;
  code: string;
  style?: StyleProp<ViewStyle>;
  onDismiss: () => void;
  scannedError: boolean;
  loadingScan: boolean;
}

function Banner(props: BannerProps) {
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (props.visible) {
        props.onDismiss();
      }
    }, 2500);
    return () => clearTimeout(timer);
  }, [props.visible]);

  return props.visible ? (
    <BannerContainer style={props.style}>
      {props.loadingScan ? null : (
        <Ionicons
          name={props.scannedError ? 'ios-close-circle' : 'ios-checkmark-circle'}
          color={props.scannedError ? '#bd3739' : '#37BD78'}
          size={30}
          style={{ margin: 10 }}
        />
      )}
      {props.loadingScan ? <Loading /> : <BannerText>{props.scannedError ? 'Scan invalid' : props.code}</BannerText>}
    </BannerContainer>
  ) : null;
}

export default Banner;
