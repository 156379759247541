// import { Tester, TestHookStore, TestReport } from 'cavy';
import AppLoading from 'expo-app-loading';
// import * as Amplitude from 'expo-analytics-amplitude';
import { contains } from 'ramda';
import React, { useEffect, useState } from 'react';
import { ApolloProvider } from 'react-apollo';
import * as Sentry from 'sentry-expo';
import { client } from './src/app/config/apollo/client';
import { BackButtonHandler, StatefulNavigator } from './src/app/screens/navigation/';
import { RootStore } from './src/app/state/stores/Root/RootStore';
import { RootStoreProvider } from './src/app/state/stores/Root/RootStoreContext';
import { setupRootStore } from './src/app/state/stores/Root/setup-root-store';
import useExpoAssetLoader from './src/app/utils/useExpoAssetLoader';
import { fonts, images } from './src/assets/index';
import AppSpec from './src/tests/AppSpec';
import { RUN_TESTS } from './src/tests/config';
import OfflineNotice from './src/app/components/OfflineNotifce/OfflineNotice';

// const testHookStore = new TestHookStore();
// const reporter = async (data: TestReport) => {
//   console.log(data);
// };

/**
 * Are we allowed to exit the app?  This is called when the back button
 * is pressed on android.
 *
 * @param routeName The currently active route name.
 */
const canExit = (routeName: string) => contains(routeName);

/**
 * This is the root component of our app.
 */
const App: React.FunctionComponent<{}> = () => {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);
  useEffect(() => {
    if (__DEV__ !== true) {
      Sentry.init({
        dsn: 'https://fe46d46bc3a243eb974725fa071350c6@sentry.io/1917467'
      });
      // Amplitude.initialize('c943138600afdc96dccb9f97d56881cc');
    }
    setupRootStore().then(setRootStore);
  }, []);

  const assetLoader = useExpoAssetLoader({
    fonts,
    images
  });

  if (!assetLoader.isReady) {
    return <AppLoading {...assetLoader.getAppLoadingProps()} />;
  }

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  //
  // This step should be completely covered over by the splash screen though.
  //
  // You're welcome to swap in your own component to render if your boot up
  // sequence is too slow though.

  if (!rootStore) {
    return null;
  }

  if (RUN_TESTS && __DEV__) {
    return (
      <RootStoreProvider value={rootStore}>
        <ApolloProvider client={client}>
          <BackButtonHandler canExit={canExit}>
            {/* <Tester specs={[AppSpec]} store={testHookStore} reporter={reporter} waitTime={1000}> */}
            <StatefulNavigator />
            {/* </Tester> */}
          </BackButtonHandler>
        </ApolloProvider>
      </RootStoreProvider>
    );
  }

  // otherwise, we're ready to render the app
  return (
    <RootStoreProvider value={rootStore}>
      <ApolloProvider client={client}>
        <BackButtonHandler canExit={canExit}>
          <StatefulNavigator />
        </BackButtonHandler>
      </ApolloProvider>
      <OfflineNotice />
    </RootStoreProvider>
  );
};

//TODO: Add initial setup, like connecting to graphQL API
//const initialSetup = // some async action

export default App;
