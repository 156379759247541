import styled from '../../utils/style/styled';

const Container = styled.View`
  margin-bottom: 25px;
`;

const Label = styled.Text`
  font-family: Verdana;
  font-size: 14px;
  color: #535151;
  margin-bottom: 7px;
`;

const InputContainer = styled.View`
  background-color: #f3f3f3;
  border-radius: 4px;
  border-color: #c2c2c2;
  border-width: 1px;
`;

const Text = styled.Text`
  margin-vertical: 18px;
  margin-horizontal: 14px;
  color: #b6b6b6;
  font-family: Verdana;
  font-size: 16px;
`;

export { Container, InputContainer, Label, Text };
