import * as React from 'react';
import { TextInput as TextInputRN, TextInputProps } from 'react-native';
import { TextInputContainer, TextInputError } from './style';

interface InputProps extends TextInputProps {
  onChangeTextCallback?: (text: string) => void;
  errorCheckCallback?: (value: string, setError: React.Dispatch<React.SetStateAction<boolean>>) => void;
  errorText?: string;
  textLabel?: string;
  themeErrorColor?: string;
  themeColor?: string;
  reference?: React.Ref<typeof TextInput>;
  withAsterisk?: boolean;
  showConstrains?: boolean;
}

export const TextInput: React.FC<InputProps> = props => {
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    setError(false);
  }, [value]);

  return (
    <TextInputContainer>
      <TextInputRN
        ref={props.reference}
        value={value}
        onBlur={() => {
          if (props.errorCheckCallback) {
            props.errorCheckCallback(value, setError);
          }
        }}
        onChangeText={(text: string) => {
          setValue(text);
          if (props.onChangeTextCallback) {
            props.onChangeTextCallback(text);
          }
        }}
        {...props}
      />
      {(error || props.showConstrains) && (
        <TextInputError style={{ color: error ? '#b00020' : 'grey' }}>{props.errorText}</TextInputError>
      )}
    </TextInputContainer>
  );
};
