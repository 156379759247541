import { createSwitchNavigator } from 'react-navigation';
import { LoginStack } from './LoginStack';
import { MainTabNavigator } from './MainTabNavigator';

export const RootNavigator = createSwitchNavigator(
  {
    LoginStack: { screen: LoginStack },
    MainTabNavigator
  },
  {
    navigationOptions: {
      gesturesEnabled: false
    }
  }
);
