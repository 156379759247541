import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

const MailIcon = props => (
  <Svg width={34} height={26} {...props}>
    <G fill="#A09E9E" fillRule="nonzero">
      <Path d="M29.85 0H4.157C1.864 0 0 1.873 0 4.178v17.644C0 24.127 1.864 26 4.158 26h25.684C32.136 26 34 24.127 34 21.822V4.185C34.007 1.88 32.143 0 29.85 0zm2.258 21.822a2.266 2.266 0 01-2.259 2.27H4.158a2.266 2.266 0 01-2.259-2.27V4.185a2.266 2.266 0 012.259-2.27h25.684a2.266 2.266 0 012.259 2.27v17.637h.007z" />
      <Path d="M21.41 13.273l8.262-7.582a.986.986 0 00.07-1.366.933.933 0 00-1.335-.071L17.012 14.718l-2.223-2.032c-.006-.007-.013-.014-.013-.021a1.407 1.407 0 00-.154-.136L5.576 4.247a.928.928 0 00-1.335.078.98.98 0 00.077 1.366l8.36 7.646-8.325 7.975a.985.985 0 00-.042 1.366c.189.2.44.308.692.308.23 0 .461-.086.643-.258l8.451-8.09 2.293 2.097a.926.926 0 001.258-.007l2.356-2.16 8.403 8.167a.933.933 0 001.335-.036.985.985 0 00-.035-1.365l-8.298-8.061z" />
    </G>
  </Svg>
);

export default MailIcon;
