import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useStores } from '../../state/stores/Root/RootStoreContext';
import { NavigationContainer } from '@react-navigation/native';
import { MainTabNavigator } from './MainTabNavigator';
import { LoginStack } from './LoginStack';

export const StatefulNavigator: React.FunctionComponent<{}> = observer(() => {
  const rootStore = useStores();

  const config = {
    screens: {}
  };

  const linking = {
    prefixes: [],
    config
  };

  return (
    <NavigationContainer linking={linking}>
      {rootStore.user.user ? <MainTabNavigator /> : <LoginStack />}
    </NavigationContainer>
  );
});
