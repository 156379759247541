import * as Updates from 'expo-updates';
const demoApi = 'https://demo.virbac.anais.tech/';
const integrationApi = 'https://integration.virbac.anais.tech/';
const productionApi = 'https://mobileapp-virbac.be/';

function getApiUrl(releaseChannel) {
  if (releaseChannel === 'default') return productionApi;
  if (
    releaseChannel === undefined ||
    releaseChannel === 'demo' ||
    releaseChannel === 'development' ||
    releaseChannel === 'preview'
  )
    return productionApi; // since releaseChannels are undefined in dev, return your default.
  if (releaseChannel.indexOf('integration') !== -1) return productionApi; // this would pick up integration-v1, integration-v2, integration-v3
  if (releaseChannel.indexOf('production') !== -1) return productionApi; // this would pick up producation-v1, producation-v2, producation-v3
}

export const URL = getApiUrl(Updates.releaseChannel);

export const GRAPHQL_URL = `${URL}en/graphql`;
