import styled from '../../utils/style/styled';

const SafeView = styled.SafeAreaView`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #0466c3;
`;

const Title = styled.Text`
  text-align: center;
  font-size: 30px;
`;

const TitleContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  border-bottom-width: 1.33px;
  border-bottom-color: #d5d5d5;
`;

const Label = styled.Text`
  color: #8c8c8c;
  font-size: 16px;
  font-family: Verdana;
  margin-vertical: 25px;
  margin-horizontal: 20px;
`;

const Container = styled.View`
  background-color: #fff;
  width: 87%;
  border-radius: 20px;
  padding-bottom: 20px;
`;

const InputContainer = styled.View`
  margin-horizontal: 25pxpx;
  margin-bottom: 40px;
`;

const ResetContainer = styled.SafeAreaView`
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`;

const ResetButton = styled.TouchableOpacity`
  background-color: #e4e4e4;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
`;

const ResetButtonText = styled.Text`
  width: 175px;
  text-align: center;
  padding-vertical: 10px;
  color: #8e8e8e;
`;

const ButtonContainer = styled.TouchableOpacity`
  flex-direction: row;
  margin-horizontal: 30px;
`;

const ButtonText = styled.Text`
  font-family: Verdana;
  text-align: center;
  font-weight: bold;
  color: ${props => (props.inverted ? '#0466c3' : '#fff')};
  font-size: 13px;
  padding-vertical: 15px;
`;

export {
  Title,
  ButtonContainer,
  ButtonText,
  SafeView,
  InputContainer,
  Label,
  TitleContainer,
  Container,
  ResetContainer,
  ResetButton,
  ResetButtonText
};
