import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import X from '../../../assets/X';
import { TextInput } from '../../components/TextInput/TextInput';
import { client } from '../../config/apollo/client';
import { useStores } from '../../state/stores/Root/RootStoreContext';
import * as MUTATIONS from '../../state/stores/User/User.mutations';
import { decodeToken } from '../../utils/jwt-helper';
import { ButtonContainer, ButtonText, Container, Label, TitleContainer } from '../ForgotPassword/style';
import { TextInputContainer, TextInputInnerContainer, TextInputTitle, Title } from '../Login/style';
import { ErrorText, Spinner, SpinnerContainer } from '../Register/style';
const { height } = Dimensions.get('window');

const Activate: React.FunctionComponent = observer(props => {
  const [activateStatus, setActivateStatus] = React.useState('unset');
  const passwordInputRef = React.useRef<React.ReactElement>(null);
  const [codeText, setCodeText] = React.useState('');
  const [passwordText, setPasswordText] = React.useState('');

  const rootStore = useStores();

  function errorCheckActivate(code: string, setActivateError: React.Dispatch<React.SetStateAction<boolean>>) {
    setActivateStatus('unset');
    if (code.length === 8 || code === '') {
      setActivateError(false);
    } else {
      setActivateError(true);
    }
  }

  function errorCheckPassword(password: string, setErrorPassword: React.Dispatch<React.SetStateAction<boolean>>) {
    setActivateStatus('unset');
    if (password.length > 3 || password === '') {
      setErrorPassword(false);
    } else {
      setErrorPassword(true);
    }
  }

  async function activate() {
    setActivateStatus('pending');
    if (codeText === '' && passwordText === '') {
      setActivateStatus('notCompletedField');
    } else {
      try {
        const { data } = (await client.mutate({
          mutation: MUTATIONS.recover,
          variables: {
            email: props.route?.params?.email.toLowerCase(),
            password: passwordText,
            code: codeText
          }
        })) as any;
        if (data.recover) {
          const decodedData = decodeToken(data.recover);
          rootStore.user.set(decodedData);
          setActivateStatus('done');
          // props.navigation.navigate('MainTabNavigator');
        } else {
          setActivateStatus('error');
        }
      } catch (error) {
        console.log(error);
        setActivateStatus('error');
      }
    }
  }

  const onPressActivate = () => {
    // props.navigation.navigate('Login');
    activate();
  };

  const handleNext = () => {
    if (passwordInputRef !== null) {
      passwordInputRef.current.focus();
    }
  };

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0466c3'
      }}
      style={{ flex: 1 }}
      enableOnAndroid
      extraHeight={150}
      enableAutomaticScroll
    >
      <Container>
        <TitleContainer>
          <Title>{rootStore.language.translate.MOBILE.newPassword}</Title>
          <TouchableOpacity
            onPress={() => {
              props.navigation.goBack();
            }}
          >
            <X />
          </TouchableOpacity>
        </TitleContainer>

        <Label>{rootStore.language.translate.MOBILE.activateTitle}</Label>

        <TextInputContainer
          activeOpacity={1}
          onPress={() => {
            handleNext();
          }}
          style={styles.textInput}
        >
          <TextInputTitle>{rootStore.language.translate.MOBILE.activationCodeLabel}</TextInputTitle>
          <TextInputInnerContainer>
            <TextInput
              placeholder={rootStore.language.translate.MOBILE.activationCodePlaceholder}
              errorText="Code has to be more than 5 characters"
              errorCheckCallback={errorCheckActivate}
              themeErrorColor="#B00020"
              returnKeyType="next"
              onChangeTextCallback={setCodeText}
            />
          </TextInputInnerContainer>
        </TextInputContainer>
        <TextInputContainer activeOpacity={1} onPress={() => {}} style={{ marginHorizontal: 25, marginBottom: 30 }}>
          <TextInputTitle>{rootStore.language.translate.MOBILE.newPasswordLabel}</TextInputTitle>
          <TextInputInnerContainer>
            <TextInput
              reference={passwordInputRef}
              placeholder={rootStore.language.translate.MOBILE.passwordPlaceholder}
              errorText="Password has to be more than 5 characters"
              errorCheckCallback={errorCheckPassword}
              secureTextEntry
              themeErrorColor="#B00020"
              returnKeyType="next"
              onChangeTextCallback={setPasswordText}
            />
          </TextInputInnerContainer>
        </TextInputContainer>

        <ButtonContainer onPress={onPressActivate}>
          {activateStatus === 'error' && (
            <ErrorText>{rootStore.language.translate.MOBILE.forgotPasswordError}</ErrorText>
          )}
          {activateStatus === 'notCompletedField' && (
            <ErrorText>{rootStore.language.translate.MOBILE.uncompleted}</ErrorText>
          )}
          {activateStatus === 'pending' && (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}
          <ButtonText>{rootStore.language.translate.MOBILE.activate}</ButtonText>
        </ButtonContainer>
      </Container>
    </KeyboardAwareScrollView>
  );
});

const styles = StyleSheet.create({
  textInput: { marginHorizontal: 25, marginBottom: 20 }
});

export default Activate;
