import { Dimensions } from 'react-native';
import styled from '../../utils/style/styled';

const { width, height } = Dimensions.get('window');

const TitleContainer = styled.View`
  width: 100%;
  padding-vertical: 22px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-bottom-color: #d8d8d8;
  border-bottom-width: 1px;
`;

const ConditionsContainer = styled.View`
  margin-top: 20px;
  margin-bottom: -15px;
  align-items: center;
  flex-direction: row;
`;

const ConditionsTextContainer = styled.View`
  flex-direction: row;
`;

const ConditionsText = styled.Text`
  color: ${props => (props.errorText ? 'crimson' : 'grey')};
  font-family: Verdana;
  font-size: 14px;
`;

const ConditionsTextLink = styled.Text`
  color: #167de0;
  margin-bottom: 2px;
  font-family: Verdana;
  font-size: 14px;
`;

const CloseContainer = styled.TouchableOpacity`
  position: absolute;
  right: 32px;
`;

const PickerTitle = styled.Text`
  font-size: 14px;
  font-family: Verdana;
  color: #535151;
  margin-bottom: 7px;
`;

const PickerText = styled.Text`
  font-size: 14px;
  font-family: Verdana;
  color: #535151;
  margin-bottom: 5px;
  text-align: center;
`;

const Picker = styled.View`
  background-color: #fff;
  max-height: 300;
  margin-horizontal: 90px;
  border-radius: 6px;
`;

const PickerTop = styled.View`
  flex-direction: row;
  margin-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  border-bottom-color: #898989;
  border-bottom-width: 1.33px;
`;

const Divider = styled.View`
  margin-top: 50px;
  margin-bottom: 15px;
  border-bottom-color: #898989;
  border-bottom-width: 1px;
`;

const PickerContainer = styled.View`
  padding-top: 10px;
`;

const SimpleText = styled.Text`
  font-size: 14px;
  font-family: Verdana;
  color: #aaabaa;
  margin-top: 20px;
  margin-bottom: 3px;
  text-align: center;
`;

const ErrorText = styled.Text`
  margin-top: 10px;
  text-align: center;
  color: red;
`;

const SpinnerContainer = styled.View`
  position: absolute;
  top: -30px;
`;

const Spinner = styled.ActivityIndicator`
  margin-bottom: 30px;
`;

export {
  SpinnerContainer,
  ConditionsText,
  ConditionsTextLink,
  Spinner,
  ConditionsTextContainer,
  ConditionsContainer,
  ErrorText,
  SimpleText,
  TitleContainer,
  Divider,
  PickerContainer,
  CloseContainer,
  Picker,
  PickerTop,
  PickerTitle,
  PickerText
};
