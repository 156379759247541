import { Dimensions, Platform } from 'react-native';
import styled from '../../utils/style/styled';

const { height } = Dimensions.get('window');

const Container = styled.View`
  flex: 1;
`;

const Header = styled.SafeAreaView`
  position: absolute;
  top: 0;
  padding-top: ${Platform.OS === 'ios' ? 0 : 20}px;
  width: 100%;
  height: ${height > 800 ? 85 : 65}px;
  background-color: #0466c3;
  justify-content: center;
`;

const HeaderTitle = styled.Text`
  color: #fff;
  font-family: Verdana;
  font-size: 18px;
  text-align: center;
`;

const FlatListBackground = styled.View`
  background-color: #0466c3;
  position: absolute;
  width: 100%;
  height: 40%;
  top: ${height > 800 ? 85 : 65}px;
`;

const RowContainer = styled.TouchableOpacity`
  margin-top: 25px;
  margin-horizontal: 30px;
  padding-bottom: 25px;
  border-bottom-color: #c4c2c2;
  border-bottom-width: ${props => (props.noBorder ? 0 : 0.7)}px;
`;

const RowInnerContainer = styled.View`
  margin-horizontal: 15px;
  flex-direction: row;
`;

const RightContainer = styled.View`
  flex: 1;
  margin-top: 5px;
  margin-horizontal: 10px;
  justify-content: center;
`;

const Title = styled.Text`
  color: #4a4a4b;
  font-family: Verdana;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 7px;
  margin-top: ${props => (props.middle ? 10 : 0)}px;
`;

const Subtitle = styled.Text`
  color: #004d97;
  font-family: Verdana;
  font-size: 13px;
  font-weight: bold;
`;

const FreeBagContainer = styled.View`
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
`;

const FreeBag = styled.Text`
  font-family: Verdana;
  font-size: 11px;
  letter-spacing: 0.7px;
  color: #292929;
  opacity: 0.72;
  margin-top: 2px;
`;

const FreeBagNumber = styled.Text`
  font-weight: bold;
  color: ${props => (props.number > 0 ? 'red' : '#292929')};
  font-size: 17px;
`;

const ImageContainer = styled.View`
  width: 42px;
  height: 70px;
  margin-right: 7px;
  margin-top: 3px;
`;

const Image = styled.Image`
  width: 70px;
  height: 86px;
  margin-top: -5px;
  margin-left: -20px;
`;

const KgContainer = styled.View`
  position: absolute;
  height: 35px;
  width: 35px;
  bottom: -18px;
  left: -25px;
  background-color: #004d97;
  border-radius: 18px;
  justify-content: center;
  align-items: center;
`;

const KgText = styled.Text`
  font-size: 13px;
  color: #fff;
  font-weight: 600;
`;

const Kg = styled.Text`
  font-size: 9px;
  color: #fff;
  font-weight: 500;
`;

export {
  Container,
  Header,
  FreeBag,
  FreeBagNumber,
  HeaderTitle,
  FlatListBackground,
  RowContainer,
  RowInnerContainer,
  RightContainer,
  Title,
  Subtitle,
  Image,
  ImageContainer,
  Kg,
  KgText,
  KgContainer,
  FreeBagContainer
};
