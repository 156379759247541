// import { useCavy } from 'cavy';
import Constants from 'expo-constants';
import * as SecureStore from 'expo-secure-store';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Dimensions, Keyboard, StatusBar, StyleSheet, TouchableOpacity, Platform, View } from 'react-native';
import Button from '../../components/Button/Button';
import { Header } from '../../components/Header';
import { TextInput } from '../../components/TextInput/TextInput';
import { client } from '../../config/apollo/client';
import { useStores } from '../../state/stores/Root/RootStoreContext';
import * as MUTATIONS from '../../state/stores/User/User.mutations';
import { decodeToken } from '../../utils/jwt-helper';
import { ReleaseText, VersionText } from '../ProfileTab/style';
import { ErrorText } from '../Register/style';
import {
  ActionsContainer,
  ButtonText,
  Container,
  LoginContainer,
  OptionsContainer,
  TextInputContainer,
  TextInputInnerContainer,
  TextInputTitle,
  Title,
  TitleContainer,
  VersionContainer
} from './style';
import AsyncStorage from '@react-native-async-storage/async-storage';

const appMetadata = require('../../../../app.json');
const { height } = Dimensions.get('window');

const Login: React.FunctionComponent = observer(props => {
  const [loginStatus, setLoginStatus] = React.useState('unset');
  const [securePassword, setSecurePassword] = React.useState(true);
  const [checked, setChecked] = React.useState(false);
  const passwordInputRef = React.useRef<React.ReactElement>(null);
  const [emailText, setEmailText] = React.useState('');
  const [passwordText, setPasswordText] = React.useState('');

  const rootStore = useStores();

  // const generateTestHook = useCavy();

  const [keyboardStatus, setKeyboardStatus] = React.useState(undefined);

  React.useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardStatus('Keyboard Shown');
    });
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardStatus('Keyboard Hidden');
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  function handleNext() {
    if (passwordInputRef !== null && passwordInputRef.current !== null) {
      passwordInputRef.current.focus();
    }
  }

  function errorCheckPassword(password: string, setErrorPassword: React.Dispatch<React.SetStateAction<boolean>>) {
    setLoginStatus('unset');

    const hasNumber = /\d/.test(password);
    const hasUpper = /[A-Z]/.test(password);
    const hasLower = /[a-z]/.test(password);
    const hasLength = password.length > 5;
    const valid = hasNumber && hasUpper && hasLower && hasLength;

    if (valid || password === '') {
      setErrorPassword(false);
    } else if (!valid) {
      setErrorPassword(true);
    }
  }

  function errorCheckEmail(email: string, setEmailError: React.Dispatch<React.SetStateAction<boolean>>) {
    setLoginStatus('unset');
    let validate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/;
    if (validate.test(email) || email === '') {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }

  async function login(email: string, password: string): Promise<void> {
    setLoginStatus('pending');

    if (email === '') {
      setLoginStatus('notCompletedField');
    } else if (password === '') {
      setLoginStatus('notCompletedField');
    } else {
      try {
        const { data } = (await client.mutate({
          mutation: MUTATIONS.login,
          variables: { email: email.toLowerCase(), password }
        })) as any;

        if (data.login) {
          if (Platform.OS === 'web') {
            AsyncStorage.setItem('token', data.login);
          } else {
            SecureStore.setItemAsync('token', data.login);
          }
          const decodedData = decodeToken(data.login);

          const userData = {
            user: {
              _id: decodedData._id,
              firstName: decodedData.firstName,
              lastName: decodedData.lastName,
              email: decodedData.email,
              clientId: decodedData.clientId,
              name: decodedData.name
            },
            activationCode: decodedData.activationCode
          };
          rootStore.user.register(userData);
          rootStore.wholesaler.fetchData();
          setLoginStatus('done');
          // props.navigation.navigate('MainTabNavigator');
        } else {
          setLoginStatus('error');
        }
      } catch (error) {
        setLoginStatus('error');
        console.log(error);
      }
    }
  }

  function onPressLogin() {
    // props.navigation.navigate('MainTabNavigator');
    login(emailText, passwordText);
  }

  return (
    <View style={{ flex: 1, backgroundColor: '#004d97' }}>
      <StatusBar barStyle="light-content" />
      <Header />
      <View style={styles.scrollview}>
        <Container
          style={{ flex: 1, marginTop: keyboardStatus === 'Keyboard Shown' && Platform.OS === 'android' ? 60 : 0 }}
        >
          {keyboardStatus === 'Keyboard Shown' ? null : (
            <TitleContainer>
              <Title>{rootStore.language.translate.MOBILE.connecter.toUpperCase()}</Title>
            </TitleContainer>
          )}

          <ActionsContainer>
            <TextInputContainer activeOpacity={1}>
              <TextInputTitle>{rootStore.language.translate.MOBILE.emailLabel}</TextInputTitle>
              <TextInputInnerContainer>
                <TextInput
                  // reference={generateTestHook('Login.InputEmail')}
                  placeholder={rootStore.language.translate.MOBILE.emailPlaceholder}
                  placeholderTextColor="lightgrey"
                  errorText={rootStore.language.translate.MOBILE.emailError}
                  errorCheckCallback={errorCheckEmail}
                  themeColor="grey"
                  themeErrorColor="#D12C29"
                  returnKeyType="next"
                  onSubmitEditing={handleNext}
                  onChangeTextCallback={setEmailText}
                />
              </TextInputInnerContainer>
            </TextInputContainer>
            <TextInputContainer activeOpacity={1}>
              <TextInputTitle>{rootStore.language.translate.MOBILE.passwordLabel}</TextInputTitle>
              <TextInputInnerContainer>
                <TextInput
                  // reference={generateTestHook('Login.InputPassword', ref => {
                  //   passwordInputRef.current = ref;
                  // })}
                  placeholder={rootStore.language.translate.MOBILE.passwordPlaceholder}
                  placeholderTextColor="lightgrey"
                  errorText={rootStore.language.translate.MOBILE.loginTextError}
                  errorCheckCallback={errorCheckPassword}
                  secureTextEntry={securePassword}
                  themeColor="grey"
                  themeErrorColor="#D12C29"
                  returnKeyType="done"
                  onChangeTextCallback={setPasswordText}
                />
              </TextInputInnerContainer>
            </TextInputContainer>

            <OptionsContainer>
              <TouchableOpacity
                onPress={() => {
                  props.navigation.navigate('ForgotPassword');
                }}
              >
                <ButtonText>{rootStore.language.translate.MOBILE.forgotPassword}</ButtonText>
              </TouchableOpacity>
            </OptionsContainer>
            <LoginContainer>
              {loginStatus === 'error' && <ErrorText>{rootStore.language.translate.MOBILE.loginError}</ErrorText>}
              {loginStatus === 'notCompletedField' && (
                <ErrorText>{rootStore.language.translate.MOBILE.uncompleted}</ErrorText>
              )}
              <Button
                // buttonRef={generateTestHook('Login.ConnectButton')}
                disabled={loginStatus === 'pending'}
                title={rootStore.language.translate.MOBILE.connecter}
                onPress={onPressLogin}
                loading={loginStatus === 'pending'}
              />
              <Button
                // buttonRef={generateTestHook('Login.RegisterButton')}
                title={rootStore.language.translate.MOBILE.register}
                onPress={() => {
                  props.navigation.navigate('Register');
                }}
                inverted
              />
            </LoginContainer>
          </ActionsContainer>
          {keyboardStatus === 'Keyboard Shown' ? null : (
            <VersionContainer>
              <ReleaseText>rc-v{appMetadata['expo']['version']}</ReleaseText>
              <VersionText>{appMetadata['aksAppVersion']}</VersionText>
            </VersionContainer>
          )}
        </Container>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  scrollview: {
    flex: 1,
    marginTop: 166,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    alignSelf: 'center',
    minWidth: Platform.OS === 'web' ? '50%' : '100%'
  },
  error: {
    textAlign: 'center',
    color: 'red',
    position: 'absolute',
    top: -20
  },
  activityIndicator: { position: 'absolute', top: 0, alignSelf: 'center' }
});

export default Login;
