import styled from '../../utils/style/styled';

const TextInputContainer = styled.TouchableOpacity`
  border-bottom-color: grey;
  margin-top: ${props => (props.noMarginTop ? 0 : 20)}px;
`;

const TextInputInnerContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding-horizontal: 18px;
  padding-vertical: 1px;
  border-color: #898989;
  border-width: 1.3px;
`;

const TextInputTitle = styled.Text`
  font-size: 14px;
  font-family: Verdana;
  color: #535151;
  margin-bottom: 7px;
`;

export { TextInputContainer, TextInputInnerContainer, TextInputTitle };
