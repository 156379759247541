import React from 'react';
import Svg, { Path } from 'react-native-svg';

function SearchSVG(props) {
  return (
    <Svg viewBox="0 0 512 512" style={{ height: 30, width: 30, marginRight: 10 }} {...props}>
      <Path d="M446.099 65.912C403.602 23.415 347.1.011 287 .011c-60.1 0-116.602 23.404-159.099 65.901S62 164.911 62 225.011c0 44.809 13.017 87.614 37.25 124.11l-86.07 86.07C4.681 443.69 0 454.991 0 467.011c0 12.021 4.681 23.321 13.18 31.819 8.772 8.773 20.296 13.159 31.82 13.159s23.047-4.386 31.82-13.159l86.07-86.07c36.496 24.233 79.301 37.251 124.11 37.251 60.1 0 116.602-23.404 159.099-65.901 42.497-42.498 65.901-99 65.901-159.099s-23.404-116.602-65.901-159.099zM55.607 477.617c-5.849 5.848-15.365 5.849-21.214-.001A14.896 14.896 0 0130 467.011c0-4.006 1.56-7.773 4.393-10.606l40.475-40.475 21.214 21.213-40.475 40.474zm61.687-61.687L96.08 394.717l21.57-21.57a230.68 230.68 0 0010.25 10.963 230.186 230.186 0 0010.963 10.251l-21.569 21.569zM287 420.011c-107.523 0-195-87.477-195-195s87.477-195 195-195 195 87.477 195 195-87.477 195-195 195z" />
      <Path d="M287 60.011c-90.981 0-165 74.019-165 165s74.019 165 165 165 165-74.019 165-165-74.019-165-165-165zm0 300c-74.439 0-135-60.561-135-135s60.561-135 135-135 135 60.561 135 135-60.561 135-135 135z" />
      <Path d="M272 300.01h30v30h-30zM287 120.011c-37.22 0-67.5 30.28-67.5 67.5h30c0-20.678 16.822-37.5 37.5-37.5s37.5 16.822 37.5 37.5c0 11.375-5.088 22.011-13.961 29.182L272 247.849v22.162h30v-7.838l27.397-22.148c15.953-12.893 25.103-32.033 25.103-52.514 0-37.22-30.28-67.5-67.5-67.5z" />
    </Svg>
  );
}

export default SearchSVG;
