import * as React from 'react';
import { Bag, Bags } from './style';

export interface BagsLeftProps {
  number: number;
}

export function BagsLeft(props: BagsLeftProps) {
  const data = [...Array(6).keys()];

  return (
    <Bags>
      {data.map(item => {
        return <Bag key={item} style={{ backgroundColor: props.number > item ? '#004D97' : '#d8d8d8' }} />;
      })}
    </Bags>
  );
}
