import React, { useEffect, useState } from 'react';
import { View, Text, Dimensions, StyleSheet } from 'react-native';
import NetInfo, { useNetInfo } from '@react-native-community/netinfo';
const { width } = Dimensions.get('window');

function MiniOfflineSign() {
  return (
    <View style={styles.offlineContainer} pointerEvents="none">
      <Text style={styles.offlineText}>Ne peut pas se connecter au serveur</Text>
    </View>
  );
}

const ServerNotice = () => {
  return <MiniOfflineSign />;
};

const styles = StyleSheet.create({
  offlineContainer: {
    backgroundColor: '#F7CF1D',
    height: 55,
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'row',
    width,
    position: 'absolute',
    top: -85
  },
  offlineText: {
    color: '#fff'
  }
});
export default ServerNotice;
