export const images = [require('./icon.png'), require('./splash.png'), require('./project-logo.png')];

export const fonts = {
  SFProDisplayHeavy: require('./fonts/SF-Pro-Display-Heavy.otf'),
  Verdana: require('./fonts/Verdana.ttf'),
  VerdanaBold: require('./fonts/VerdanaBold.ttf'),
  'Material Icons': require('../../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialIcons.ttf'),
  MaterialIcons: require('../../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialIcons.ttf'),
  FontAwesome: require('../../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome.ttf')
};
