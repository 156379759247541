import { Dimensions, Platform } from 'react-native';
import styled from '../../utils/style/styled';

const { height } = Dimensions.get('window');

const Container = styled.View`
  flex: 1;
`;

const Label = styled.Text`
  color: #8c8c8c;
  font-size: 16px;
  font-family: Verdana;
`;

const Title = styled.Text`
  color: rgba(0, 0, 0, 0.8);
  font-size: 17px;
  font-weight: bold;
  font-family: Verdana;
  margin-bottom: 30px;
`;
const BottomButtons = styled.View`
  margin-top: 15px;
`;

const Button = styled.TouchableOpacity`
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
`;

const ButtonText = styled.Text`
  color: #167de0;
  font-family: Verdana;
  font-size: 16px;
  text-decoration-color: #167de0;
  margin-left: 15px;
`;

const CloseContainer = styled.TouchableOpacity`
  position: absolute;
  right: 26;
  top: ${Platform.OS !== 'ios' ? (height > 810 ? 60 : 40) : 52};
`;

export { Container, Label, Title, BottomButtons, Button, ButtonText, CloseContainer };
