import { Platform } from 'react-native';
import styled from '../../utils/style/styled';

const TextInputContainer = styled.View``;

const TextInputError = styled.Text`
  color: #b00020;
  flex: 1;
  margin-top: ${Platform.OS === 'android' ? '45' : '35'};
  left: -20;
  right: -20;
  position: absolute;
  flex-wrap: wrap;
`;

export { TextInputContainer, TextInputError };
