import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { NavigationStoreModel } from '../../../screens/navigation/NavigationStore';
import { LanguageStore } from '../Language/Language';
import { ProductsModel } from '../Products/Products';
import { UserModel } from '../User/User';
import { WholesalerModel } from '../Wholesalers/Wholesalers';
const en = require('../Language/en.json');

export const RootStoreModel: any = types
  .model('RootStore')
  .props({
    navigationStore: types.optional(NavigationStoreModel, {}),
    language: types.optional(LanguageStore, () =>
      LanguageStore.create({ language: 'en', languageData: JSON.stringify(en), state: 'pending' })
    ),
    products: types.optional(ProductsModel, () => ProductsModel.create({ data: [] })),
    user: types.optional(UserModel, () => UserModel.create({ token: '' })),
    wholesaler: types.optional(WholesalerModel, () => {
      WholesalerModel.create({ data: null });
    })
  })
  .actions(self => {
    return {
      afterCreate() {
        console.log(self);
      }
    };
  })
  .views(self => ({
    get mainData() {
      return self.main;
    }
  }));

/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>;
/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>;
