import { FontAwesome } from '@expo/vector-icons';
// import KeyboardAwareScrollView from '@pietile-native-kit/keyboard-aware-scrollview';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { StyleSheet } from 'react-native';
import { TextInput } from 'react-native-paper';
import styled from '../../utils/style/styled';

const AvoidKeyboard = styled.View`
  flex: 1;
  background-color: #004d97;
`;

const Container = styled.View`
  flex: 1;
`;

const SocialButton = styled.View`
  background-color: ${props => props.background || 'grey'};
  border-radius: 5px;
  flex-direction: row;
  padding-vertical: 12px;
  margin-top: ${props => props.marginTop || 0}px;
  margin-bottom: ${props => props.marginBottom || 0}px;
  min-width: 50%;
  shadow-color: #000;
  shadow-offset: 0px 1px;
  shadow-opacity: 0.22;
  shadow-radius: 2.22px;
  elevation: 3;
`;

const SocialIcon = styled(FontAwesome)`
  margin-left: 20px;
`;

const SocialButtonTextContainer = styled.View`
  align-items: center;
`;

const TitleContainer = styled.View`
  width: 100%;
  padding-vertical: 22px;
  align-items: center;
  border-bottom-color: #d8d8d8;
  border-bottom-width: 1px;
`;
const Title = styled.Text`
  color: #004d97;
  font-family: Verdana;
  font-size: 20px;
`;

const LineContainer = styled.View`
  justify-content: center;
`;
const Line = styled.View`
  border-width: ${StyleSheet.hairlineWidth}px;
  border-color: #000;
  min-width: 55%;
`;

const VersionContainer = styled.View`
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  margin-horizontal: 30px;
`;

const LineTextContainer = styled.View`
  position: absolute;
  align-self: center;
  background-color: #fff;
  padding-horizontal: 10px;
`;
const LineText = styled.Text`
  font-weight: bold;
`;

const ActionsContainer = styled.View`
  flex: 1;
  justify-content: center;
  width: 100%;
  padding-horizontal: 30px;
`;

const TextInputContainer = styled.TouchableOpacity`
  border-bottom-color: grey;
  margin-top: 20px;
`;

const TextInputInnerContainer = styled.View`
  border-radius: 8px;
  padding-horizontal: 18px;
  padding-vertical: 14px;
  border-color: #898989;
  border-width: 1.3px;
  margin-bottom: 5px;
`;

const TextInputTitle = styled.Text`
  font-size: 14px;
  font-family: Verdana;
  color: #535151;
  margin-bottom: 7px;
`;

const StyledTextInput = styled(TextInput)`
  height: 56px;
`;

const SecurePassword = styled.TouchableOpacity`
  height: 56px;
  position: absolute;
  right: 10px;
  justify-content: center;
`;

const TextInputError = styled.Text`
  color: #b00020;
  margin-left: 12px;
  padding-vertical: 5px;
  position: absolute;
  bottom: -25px;
`;

const OptionsContainer = styled.View`
  margin-top: 30px;
`;

const CheckButton = styled.TouchableWithoutFeedback`
  flex-direction: row;
  align-items: center;
`;

const CheckButtonContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

const ButtonText = styled.Text`
  color: #167de0;
  font-family: Verdana;
  font-size: 14px;
  text-decoration: underline;
  text-decoration-color: #167de0;
`;

const LoginContainer = styled.View`
  /* justify-content: center; */
  align-items: center;
  /* margin-top: 30; */
`;

const LoginButton = styled.TouchableOpacity`
  background-color: #e4e4e4;
  border-radius: 5px;
  padding-horizontal: 75px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const LoginButtonText = styled.Text`
  text-align: center;
  padding-vertical: 10px;
  color: #8e8e8e;
`;

export {
  LoginButtonText,
  LoginButton,
  LoginContainer,
  ButtonText,
  CheckButtonContainer,
  CheckButton,
  OptionsContainer,
  TextInputError,
  SecurePassword,
  StyledTextInput,
  TextInputContainer,
  ActionsContainer,
  LineText,
  LineTextContainer,
  Line,
  LineContainer,
  TextInputInnerContainer,
  TextInputTitle,
  Title,
  TitleContainer,
  VersionContainer,
  SocialButtonTextContainer,
  SocialIcon,
  SocialButton,
  Container,
  AvoidKeyboard
};
