import React from 'react';
import Svg, { Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

const ScanIcon = props => (
  <Svg width={34} height={34} style={{ transform: [{ scale: 0.7 }] }} {...props}>
    <Path
      d="M22.667 21.313v-1.578h-2.709v-3.84h5.417v2.71-2.71h2.708v2.71h-2.708v2.707h2.708v4.063h-2.708v2.708h-2.708v-2.708h-4.063v2.708h-4.24v-5.416h5.594v-1.355h2.709zm0 0v4.062h2.708v-4.063h-2.708m-16.25-.229h7v7h-7v-7zM9.125 9.125v4h4v-4M8 15v4h4v-4m10-5v5h3v-5h-3m-3 2v3h3v-3h-3m6-5v3h3V7h-3m0 5v3h3v-3h-3m-8-6v3h5V6h-5m-4 0v3h3V6h-3m.188 9.896h5.416v5.416h-2.708v-2.708h-2.709v-2.708m2.709-6.771h2.708v5.417h-2.708V9.125M3.708 3.708v5.417H1V3.708A2.708 2.708 0 013.708 1h5.417v2.708H3.708M30.792 1A2.708 2.708 0 0133.5 3.708v5.417h-2.708V3.708h-5.417V1h5.417M3.708 25.375v5.417h5.417V33.5H3.708A2.708 2.708 0 011 30.792v-5.417h2.708m27.084 5.417v-5.417H33.5v5.417a2.708 2.708 0 01-2.708 2.708h-5.417v-2.708h5.417z"
      fill={props.focused ? '#004D97' : '#A09E9E'}
      fillRule="nonzero"
      stroke="#F7F7F7"
      strokeWidth={0.7}
    />
  </Svg>
);

export default ScanIcon;
