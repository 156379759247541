import gql from 'graphql-tag';
import { flow, types } from 'mobx-state-tree';
import { client } from '../../../config/apollo/client';
import { Wholesaler } from '../../models/Wholesaler';

const wholesalersQuery = gql`
  {
    wholesalers {
      items {
        _id
        name
        email
        dateCreated
        dateUpdated
      }
    }
  }
`;

export const WholesalerModel = types
  .model('WholesalerModel', {
    data: types.maybeNull(types.array(Wholesaler)),
    state: types.maybe(types.enumeration(['pending', 'done', 'error']))
  })
  .actions(self => {
    const fetchData = flow(function*() {
      self.state = 'pending';
      try {
        const res = yield client.query({ query: wholesalersQuery });
        self.data = res.data.wholesalers.items;
        self.state = 'done';
      } catch (error) {
        console.log(error);
        self.state = 'error';
      }

      return self.data;
    });
    return {
      fetchData,
      afterCreate() {
        fetchData();
      }
    };
  });
