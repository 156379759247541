import React from 'react';
import Svg, { Defs, G, Mask, Path, Use } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

const VirbacLogo = props => (
  <Svg width={150} height={35} {...props}>
    <Defs>
      <Path id="prefix__a" d="M.071.616H149.92V3.2H.07z" />
    </Defs>
    <G fill="none" fillRule="evenodd">
      <G transform="translate(0 31.5)">
        <Mask id="prefix__b" fill="#fff">
          <Use xlinkHref="#prefix__a" />
        </Mask>
        <Path
          d="M75.723 3.2c41.38 0 74.196-.585 74.196-1.11 0-.53-32.941-1.474-74.323-1.474C34.22.616.071 1.56.071 2.09c0 .525 34.275 1.11 75.652 1.11"
          fill="#EC1D24"
          mask="url(#prefix__b)"
        />
      </G>
      <Path
        d="M116.67 25.942c.113.08-.282.486-.135.558-3.386-.017-7.847-.784-7.028-3.33l1.454-4.879c-1.881.477-3.551.626-5.594.876-2.966.352-5.977.409-6.608 2.345-.635 1.964 2.175 2.304 5.324 2.304 1.247 0 3.082-.25 4.386-.44.191-.025-.017.577.174.55l-4.275 2.378c-1.041.097-2.31.196-3.323.196-6.006 0-9.801-2.34-8.918-4.961 2.095-6.201 19.222-3.904 19.925-6.64.341-1.32-2.642-1.723-4.845-1.723-2.876 0-5.478.612-8.229 1.239-.147.032-.167-.551-.312-.512l5.43-3.209 3.93-.194c6.642.095 10.963 1.273 9.779 5.105l-1.54 4.764c-.761 2.34-1.501 4.698.405 5.573M144.934 22.601c-1.932.77-4.672 1.217-6.592 1.217-6.667 0-11.4-2.114-10.21-5.763.983-3.008 5.597-4.869 11.416-4.869 2.701 0 5.421.505 6.817 1.553h.39L148 10.93a57.416 57.416 0 00-6.748-.431c-9.065 0-18.29 2.933-20.07 8.381-1.355 4.12 5.008 7.619 14.254 7.619 2.634 0 5.764-.164 8.444-.67l1.054-3.229zM62 26.038c2.559.204 6.135.462 9.416.462 10.771 0 18.728-3.44 20.332-8.336 1.405-4.273-3.224-7.298-9.978-7.298-1.417 0-3.472.084-4.913.335l-4.153 3.128c1.741-.438 3.59-.679 5.476-.679 5.98 0 7.811 2.05 6.816 5.077-1.016 3.089-5.16 5.01-11.676 5.01-1.653 0-2.879-.105-4.41-.326L75.112 4.5l-.533.093c-.99.504-2.505.405-3.93.405H68.9L62 26.038zM44 25.5h8.214l.182-.554c-2.334-.761-1.7-1.618-1.231-3.019l1.425-4.365c1.05-3.142 3.074-4.08 7.544-4.08 1 0 2.167.287 2.695.653L64 10.614l-2.461-.112c-2.939 0-5.254.76-7.196 1.893l.626-1.895h-.563c-.946.37-2.368.4-3.724.4h-1.818L44 25.5zM31.755 6.08C32.4 4.485 33.07 2.815 35 2.105l-.022-.604-6.549.006-7.12 19.876L7.638 1.56 0 1.594v.604c2.584 1.068 3.301 2.38 4.416 3.977L15.604 22.2c1.7 2.486 5.676 4.717 9.185 4.234L31.755 6.08zM32 25.5h8.19l.205-.55c-2.337-.758-1.706-1.701-1.238-3.093L43 10.5h-.569c-1.409.145-2.787.145-4.142.145h-1.357L32 25.5z"
        fill="#FEFEFE"
      />
      <Path fill="#EC1D24" d="M38 5.5h6.37L46 .5h-6.44z" />
    </G>
  </Svg>
);

export default VirbacLogo;
