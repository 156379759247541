import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ButtonContainer, ButtonText, Loading } from './style';

export interface ButtonProps {
  onPress: () => void | undefined;
  title: String;
  inverted?: boolean;
  loading?: boolean;
}

const Button: React.FunctionComponent<ButtonProps> = observer(props => {
  return (
    <ButtonContainer ref={props.buttonRef} onPress={props.onPress} inverted={props.inverted} {...props}>
      <ButtonText inverted={props.inverted}>{props.title}</ButtonText>
      {props.loading ? <Loading color="#fff" /> : null}
    </ButtonContainer>
  );
});

export default Button;
