import React from 'react';
import Svg, { Defs, G, LinearGradient, Path, Stop } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

function ScannerSVG(props) {
  return (
    <Svg width={205} height={168} viewBox="0 0 205 168" {...props}>
      <Defs>
        <LinearGradient x1="92.867%" y1="89.858%" x2="-4.64%" y2="0%" id="prefix__a">
          <Stop stopColor="#0466C3" offset="0%" />
          <Stop stopColor="#004D97" offset="100%" />
          <Stop stopColor="#004D97" offset="100%" />
        </LinearGradient>
        <LinearGradient x1="53.916%" y1="89.858%" x2="45.009%" y2="0%" id="prefix__b">
          <Stop stopColor="#0466C3" offset="0%" />
          <Stop stopColor="#004D97" offset="100%" />
          <Stop stopColor="#004D97" offset="100%" />
        </LinearGradient>
        <LinearGradient x1="54.662%" y1="89.858%" x2="44.058%" y2="0%" id="prefix__c">
          <Stop stopColor="#0466C3" offset="0%" />
          <Stop stopColor="#004D97" offset="100%" />
          <Stop stopColor="#004D97" offset="100%" />
        </LinearGradient>
        <LinearGradient x1="56.421%" y1="89.858%" x2="41.816%" y2="0%" id="prefix__d">
          <Stop stopColor="#0466C3" offset="0%" />
          <Stop stopColor="#004D97" offset="100%" />
          <Stop stopColor="#004D97" offset="100%" />
        </LinearGradient>
      </Defs>
      <G fillRule="nonzero" stroke="#FFF" fill="none">
        <Path
          d="M195.106 31.05c-.657-12.583-11.1-22.62-23.845-22.62H67.479c-1.524 0-2.92.855-3.61 2.213-10.287 20.187-10.286 44.288 0 64.471a4.053 4.053 0 003.61 2.213h27.529l.032.002.037-.002h19.547V91.58a4.053 4.053 0 004.053 4.053h18.121l15.075 67.681a4.053 4.053 0 003.955 3.172c8.67 0 16.753-3.88 22.177-10.644 5.422-6.763 7.45-15.495 5.564-23.956l-12.15-54.563c12.673-.083 23.034-10.084 23.687-22.614 4.251-1.123 7.395-5.001 7.395-9.6V40.65c0-4.6-3.144-8.477-7.395-9.6zM88.757 69.222h-18.74c-7.68-16.664-7.68-36.018 0-52.685H88.76c-6.727 16.871-6.728 35.813-.002 52.685zm33.972 18.306v-10.2h9.99l2.273 10.2h-12.263zm52.928 46.122a20.214 20.214 0 01-3.977 17.123 20.206 20.206 0 01-12.659 7.36l-17.997-80.805h22.09l12.543 56.322zM171.26 69.22H97.58c-7.681-16.668-7.68-36.018 0-52.685h73.68c8.162 0 14.895 6.23 15.694 14.185H129.55a4.053 4.053 0 00-4.052 4.053v16.21a4.053 4.053 0 004.052 4.054h57.403c-.798 7.953-7.532 14.183-15.694 14.183zm23.134-24.112a1.826 1.826 0 01-1.823 1.824h-58.967v-8.106h58.967c1.005 0 1.823.818 1.823 1.824v4.458z"
          fill="url(#prefix__a)"
          transform="translate(1 1)"
        />
        <Path
          d="M20.67 88.538a4.053 4.053 0 002.866-6.918c-9.95-9.95-15.43-23.181-15.43-37.254 0-14.073 5.48-27.304 15.43-37.254a4.053 4.053 0 00-5.731-5.732C6.323 12.862 0 28.128 0 44.366 0 60.604 6.323 75.869 17.805 87.35a4.038 4.038 0 002.866 1.187z"
          fill="url(#prefix__b)"
          transform="translate(1 1)"
        />
        <Path
          d="M24.316 44.366c0-9.743 3.794-18.902 10.684-25.792a4.054 4.054 0 00-5.732-5.731c-8.42 8.42-13.057 19.615-13.057 31.523 0 11.907 4.637 23.102 13.057 31.522a4.04 4.04 0 002.866 1.187A4.053 4.053 0 0035 70.157c-6.89-6.889-10.684-16.048-10.684-25.791z"
          fill="url(#prefix__c)"
          transform="translate(1 1)"
        />
        <Path
          d="M46.462 58.694a20.132 20.132 0 01-5.935-14.328 20.13 20.13 0 015.935-14.329 4.053 4.053 0 00-5.731-5.731c-5.358 5.358-8.31 12.482-8.31 20.06 0 7.577 2.952 14.701 8.31 20.06a4.04 4.04 0 002.866 1.187 4.054 4.054 0 002.865-6.919z"
          fill="url(#prefix__d)"
          transform="translate(1 1)"
        />
      </G>
    </Svg>
  );
}

export default ScannerSVG;
