import React from 'react';
import Svg, { Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

const IconDown = props => (
  <Svg width={13} height={7} {...props}>
    <Path d="M12.498.134L6.5 6.864.502.134z" fill="#D8D8D8" fillRule="evenodd" />
  </Svg>
);

export default IconDown;
