import { Ionicons } from '@expo/vector-icons';
// import { useCavy } from 'cavy';
import * as SecureStore from 'expo-secure-store';
import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import {
  FlatList,
  Platform,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View
} from 'react-native';
import { CheckBox } from 'react-native-elements';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Modal from 'react-native-modal';
import X from '../../../assets/X';
import Button from '../../components/Button/Button';
import { Header } from '../../components/Header';
import Input from '../../components/Input/Input';
import { TextInput } from '../../components/TextInput/TextInput';
import { client } from '../../config/apollo/client';
import { useStores } from '../../state/stores/Root/RootStoreContext';
import * as MUTATIONS from '../../state/stores/User/User.mutations';
import { decodeToken } from '../../utils/jwt-helper';
import {
  ActionsContainer,
  ButtonText,
  Container,
  LoginContainer,
  SecurePassword,
  TextInputContainer,
  TextInputInnerContainer,
  TextInputTitle,
  Title
} from '../Login/style';
import {
  CloseContainer,
  ConditionsContainer,
  ConditionsText,
  ConditionsTextContainer,
  ConditionsTextLink,
  Divider,
  ErrorText,
  Picker,
  PickerContainer,
  PickerText,
  PickerTitle,
  PickerTop,
  SimpleText,
  Spinner,
  TitleContainer
} from './style';
import AsyncStorage from '@react-native-async-storage/async-storage';

const Register: React.FunctionComponent = props => {
  const [acceptedConditions, setAcceptedConditions] = React.useState(false);
  const [registerStatus, setRegisterStatus] = React.useState('unset');
  const [modal, setModal] = React.useState(false);
  const [wholesaler, setWholesaler] = React.useState({ _id: null, name: 'Grossiste' });
  const passwordInputRef = React.useRef<React.ReactElement>(null);
  const scrollRef = React.useRef<React.ReactElement>(null);
  const wholesalerInputRef = React.useRef<React.ReactElement>(null);
  const emailInputRef = React.useRef<React.ReactElement>(null);
  const clientInputRef = React.useRef<React.ReactElement>(null);
  const [securePassword, setSecurePassword] = React.useState(true);
  const [checked, setChecked] = React.useState(false);
  const [clientText, setClientText] = React.useState('');
  const [emailText, setEmailText] = React.useState('');
  const [passwordText, setPasswordText] = React.useState('');
  const [wholesalerText, setWholesalerText] = React.useState('');

  const rootStore = useStores();

  // const generateTestHook = useCavy();

  React.useEffect(() => {
    rootStore.wholesaler.fetchData();
  }, []);

  function renderRightPassword() {
    return (
      <SecurePassword onPressIn={() => setSecurePassword(false)} onPressOut={() => setSecurePassword(true)}>
        <Ionicons name={securePassword ? 'md-eye' : 'md-eye-off'} size={20} color="grey" />
      </SecurePassword>
    );
  }

  function handleNext(value) {
    if (value === 'email' && emailInputRef !== null) {
      emailInputRef.current.focus();
    }
    if (value === 'password' && passwordInputRef !== null) {
      passwordInputRef.current.focus();
    }
    if (value === 'client' && clientInputRef !== null) {
      clientInputRef.current.focus();
    }
  }

  function errorCheckEmail(email: string, setEmailError: React.Dispatch<React.SetStateAction<boolean>>) {
    setRegisterStatus('unset');
    let validate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/;
    if (validate.test(email) || email === '') {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }

  function errorCheckPassword(password: string, setErrorPassword: React.Dispatch<React.SetStateAction<boolean>>) {
    setRegisterStatus('unset');

    const hasNumber = /\d/.test(password);
    const hasUpper = /[A-Z]/.test(password);
    const hasLower = /[a-z]/.test(password);
    const hasLength = password.length > 5;
    const valid = hasNumber && hasUpper && hasLower && hasLength;

    if (valid || password === '') {
      setErrorPassword(false);
    } else if (!valid) {
      setErrorPassword(true);
    }
  }

  async function register(clientId: string, email: string, password: string, wholesaler: string): Promise<void> {
    setRegisterStatus('pending');

    if (emailText || passwordText || clientText === '' || wholesalerText === '') {
      setRegisterStatus('notCompletedField');
    } else {
      try {
        const { data } = (await client.mutate({
          mutation: MUTATIONS.register,
          variables: { clientId, email: email.toLowerCase(), password, wholesaler }
        })) as any;

        if (data.registerClientUser) {
          if (Platform.OS === 'web') {
            AsyncStorage.setItem('token', data.registerClientUser);
          } else {
            SecureStore.setItemAsync('token', data.registerClientUser);
          }
          const decodedData = decodeToken(data.registerClientUser);

          const userData = {
            user: {
              _id: decodedData._id,
              firstName: decodedData.firstName,
              lastName: decodedData.lastName,
              email: decodedData.email,
              clientId: decodedData.clientId,
              name: decodedData.name
            },
            activationCode: decodedData.activationCode
          };
          rootStore.user.register(userData);
          setRegisterStatus('done');
          // props.navigation.navigate('MainTabNavigator');
        } else {
          setRegisterStatus('error');
        }
      } catch (error) {
        setRegisterStatus('error');
        console.log(error);
      }
    }
  }

  function onPressRegister() {
    if (acceptedConditions) {
      register(clientText, emailText, passwordText, wholesaler._id);
    } else {
      setRegisterStatus('conditionsNotAccepted');
    }
  }

  const onPressClose = () => {
    props.navigation.goBack();
  };

  const renderPickerItem = ({ item, index }) => {
    return (
      <TouchableOpacity
        // ref={generateTestHook(`Register.WholesalerRow[${index}]`)}
        key={item._id}
        onPress={() => {
          setWholesaler({ _id: item._id, name: item.name });
          setModal(false);
        }}
      >
        <PickerText style={{ marginVertical: 20 }}>{item.name}</PickerText>
      </TouchableOpacity>
    );
  };

  const renderEmptyComponent = () => {
    return (
      <View style={styles.emptyContainer}>
        <Text style={styles.loading}>{rootStore.language.translate.MOBILE.loading}</Text>
        <Spinner color="grey" />
      </View>
    );
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: '#004d97'
      }}
    >
      <StatusBar barStyle="light-content" />
      <Header withImage />
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps="always"
        enableOnAndroid
        extraHeight={180}
        enableAutomaticScroll
        contentContainerStyle={styles.scrollview}
      >
        <Container>
          <TitleContainer>
            <Title>{rootStore.language.translate.MOBILE.registerTitle}</Title>
            <CloseContainer onPress={onPressClose} hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}>
              <X />
            </CloseContainer>
          </TitleContainer>

          <ActionsContainer>
            <TextInputContainer activeOpacity={1} onPress={() => handleNext('client')}>
              <TextInputTitle>{rootStore.language.translate.MOBILE.clientIdLabel}</TextInputTitle>
              <TextInputInnerContainer>
                <TextInput
                  // reference={generateTestHook('Register.InputClient', ref => {
                  //   clientInputRef.current = ref;
                  // })}
                  placeholder={rootStore.language.translate.MOBILE.clientIdLabel}
                  errorText={rootStore.language.translate.MOBILE.clientTextError}
                  themeErrorColor="#B00020"
                  returnKeyType="next"
                  onSubmitEditing={() => handleNext('email')}
                  onChangeTextCallback={setClientText}
                />
              </TextInputInnerContainer>
            </TextInputContainer>

            <TextInputContainer activeOpacity={1} onPress={() => handleNext('email')}>
              <TextInputTitle>{rootStore.language.translate.MOBILE.emailLabel}</TextInputTitle>
              <TextInputInnerContainer>
                <TextInput
                  // reference={generateTestHook('Register.InputEmail', ref => {
                  //   emailInputRef.current = ref;
                  // })}
                  placeholder={rootStore.language.translate.MOBILE.emailPlaceholder}
                  errorText={rootStore.language.translate.MOBILE.emailError}
                  errorCheckCallback={errorCheckEmail}
                  themeErrorColor="#B00020"
                  returnKeyType="next"
                  onSubmitEditing={() => handleNext('password')}
                  onChangeTextCallback={setEmailText}
                />
              </TextInputInnerContainer>
            </TextInputContainer>

            <TextInputContainer
              activeOpacity={1}
              onPress={() => {
                handleNext('password');
              }}
            >
              <TextInputTitle>{rootStore.language.translate.MOBILE.passwordLabel}</TextInputTitle>
              <TextInputInnerContainer>
                <TextInput
                  // reference={generateTestHook('Register.InputPassword', ref => {
                  //   passwordInputRef.current = ref;
                  // })}
                  placeholder={rootStore.language.translate.MOBILE.passwordPlaceholder}
                  errorText={rootStore.language.translate.MOBILE.passwordError}
                  errorCheckCallback={errorCheckPassword}
                  secureTextEntry={securePassword}
                  themeErrorColor="#B00020"
                  returnKeyType="done"
                  onChangeTextCallback={setPasswordText}
                />
              </TextInputInnerContainer>
            </TextInputContainer>

            <Divider />

            <TouchableWithoutFeedback
              // ref={generateTestHook('Register.WholesalerModal')}
              onPress={() => {
                setModal(true);
              }}
            >
              <PickerContainer pointerEvents="box-only">
                <Input
                  editable={false}
                  title={rootStore.language.translate.MOBILE.wholesalerLabel}
                  reference={wholesalerInputRef}
                  placeholder={rootStore.language.translate.MOBILE.wholesalerPlaceholder}
                  value={wholesaler._id === null ? '' : wholesaler.name}
                  errorText={rootStore.language.translate.MOBILE.passwordError}
                  themeErrorColor="#B00020"
                  returnKeyType="done"
                  dropDown
                  onChangeTextCallback={setWholesalerText}
                />
              </PickerContainer>
            </TouchableWithoutFeedback>

            <ConditionsContainer>
              <CheckBox
                // ref={generateTestHook('Register.AcceptButton')}
                containerStyle={{
                  justifyContent: 'center',
                  backgroundColor: '#fff',
                  borderWidth: 0,
                  padding: 0,
                  marginRight: 5,
                  marginLeft: 5
                }}
                uncheckedColor={registerStatus === 'conditionsNotAccepted' ? 'crimson' : '#bfbfbf'}
                checked={acceptedConditions}
                onPress={() => {
                  setRegisterStatus('unset');
                  setAcceptedConditions(condition => !condition);
                }}
              />
              <ConditionsTextContainer>
                <ConditionsText errorText={registerStatus === 'conditionsNotAccepted'}>
                  {rootStore.language.translate.MOBILE.conditionsText}{' '}
                </ConditionsText>
                <TouchableOpacity
                  onPress={() => {
                    WebBrowser.openBrowserAsync(rootStore.language.translate.MOBILE.conditionsLink);
                  }}
                >
                  <ConditionsTextLink>{rootStore.language.translate.MOBILE.conditionsTextLink}</ConditionsTextLink>
                </TouchableOpacity>
              </ConditionsTextContainer>
            </ConditionsContainer>

            <LoginContainer>
              {registerStatus === 'error' && <ErrorText>{rootStore.language.translate.MOBILE.registerError}</ErrorText>}
              {registerStatus === 'notCompletedField' && (
                <ErrorText>{rootStore.language.translate.MOBILE.uncompleted}</ErrorText>
              )}
              <Button
                // buttonRef={generateTestHook('Register.RegisterButton')}
                title={rootStore.language.translate.MOBILE.registerButton}
                onPress={onPressRegister}
              />

              <SimpleText>{rootStore.language.translate.MOBILE.haveAccount}</SimpleText>
              <TouchableOpacity
                onPress={() => {
                  props.navigation.navigate('Login');
                }}
              >
                <ButtonText>{rootStore.language.translate.MOBILE.connecter}</ButtonText>
              </TouchableOpacity>
            </LoginContainer>
          </ActionsContainer>
        </Container>
      </KeyboardAwareScrollView>
      <Modal
        isVisible={modal}
        useNativeDriver={true}
        style={styles.modal}
        hideModalContentWhileAnimating={true}
        onCancelPressed={() => setModal(false)}
        onBackButtonPressed={() => setModal(false)}
        onModalHide={() => {
          setModal(false);
        }}
        onBackdropPress={() => {
          setModal(false);
        }}
      >
        <StatusBar hidden />

        <Picker>
          <PickerTop style={styles.pickerTop}>
            <PickerTitle>{rootStore.language.translate.MOBILE.pickWholesaler}</PickerTitle>
            <X
              style={styles.close}
              hitSlop={{ top: 10, right: 10, left: 10, bottom: 10 }}
              onPress={() => {
                setModal(false);
              }}
            />
          </PickerTop>
          <FlatList
            keyExtractor={item => item._id}
            renderItem={renderPickerItem}
            data={rootStore.wholesaler.data}
            contentContainerStyle={{ marginBottom: 20 }}
            ListEmptyComponent={renderEmptyComponent}
          />
        </Picker>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  scrollview: {
    flex: 1,
    marginTop: 166,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    alignSelf: 'center',
    minWidth: Platform.OS === 'web' ? '50%' : '100%'
  },
  modal: { margin: 0 },
  pickerTop: {
    flexDirection: 'row',
    marginTop: 10,
    paddingBottom: 10,
    justifyContent: 'center',
    borderBottomColor: '#898989',
    borderBottomWidth: 1.33
  },
  emptyContainer: { minHeight: 300 },
  loading: { textAlign: 'center', marginTop: 30, marginBottom: 10 },

  close: { position: 'absolute', right: 10, transform: [{ scale: 0.7 }] }
});

export default Register;
