import { types } from 'mobx-state-tree';
import { User } from '../../models/User';

// export const clientQuery = gql`
//   query client($id: String!) {
//     client(id: $id) {
//       clientId
//       name
//       email
//       wholesaler {
//         _id
//         name
//       }
//       salesRep {
//         _id
//         name
//       }
//     }
//   }
// `;

export const UserModel = types
  .model('UserModel', {
    user: types.maybeNull(User),
    // clientWholesaler: types.optional(types.string, ''),
    // clientWholesalerId: types.optional(types.string, ''),
    // clientSalesRep: types.optional(types.string, ''),
    // clientSalesRepId: types.optional(types.string, ''),
    // clientIdName: types.optional(types.string, ''),
    // clientName: types.optional(types.string, ''),
    // clientEmail: types.optional(types.string, ''),
    state: types.maybe(types.enumeration(['pending', 'done', 'error']))
  })
  .actions(self => {
    // const fetchClientInfo = flow(function*() {
    //   self.state = 'pending';
    //   try {
    //     if (self.user && self.user.clientId) {
    //       const res = (yield client.query({
    //         query: clientQuery,
    //         variables: {
    //           id: self.user.clientId
    //         }
    //       })) as any;
    //       // self.clientWholesaler = res.data.client.wholesaler.name;
    //       // self.clientWholesalerId = res.data.client.wholesaler._id;
    //       // self.clientSalesRep = res.data.client.salesRep.name;
    //       // self.clientSalesRepId = res.data.client.salesRep._id;
    //       // self.clientIdName = res.data.client.clientId;
    //       // self.clientName = res.data.client.name;
    //       // self.clientEmail = res.data.client.email;
    //       self.state = 'done';
    //     } else {
    //       self.state = 'error';
    //     }
    //   } catch (error) {
    //     self.state = 'error';
    //     console.log('error user', error);
    //   }
    // });

    // const setUserRep = rep => {
    //   self.clientSalesRep = rep.name;
    //   self.clientSalesRepId = rep.id;
    // };

    return {
      register(userData) {
        self.user = userData.user;
      },
      set(user) {
        self.user = user;
      }
      // setUserRep,
      // fetchClientInfo
    };
  });
