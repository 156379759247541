import styled from '../../utils/style/styled';

const InnerContainer = styled.ScrollView``;

const Button = styled.TouchableOpacity`
  margin-bottom: 10px;
`;

const Container = styled.View`
  flex: 1;
`;

const ButtonContainer = styled.View`
  align-items: center;
  margin-bottom: 20px;
`;

const CloseContainer = styled.TouchableOpacity`
  position: absolute;
  right: 10px;
  transform: scale(0.7);
`;

const VersionText = styled.Text`
  font-sizepx;
  color: #cccccc;
  /* position: absolute; */
  /* right: -10; */
  /* bottom: -10; */
`;

const ReleaseText = styled.Text`
  font-size: 9px;
  color: #cccccc;
  /* position: absolute; */
  /* left: 0; */
  /* bottom: -10; */
`;

const AboutButton = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 5px;
`;

const AboutButtonText = styled.Text`
  color: #167de0;
  font-family: Verdana;
  font-size: 16px;
  text-decoration-color: #167de0;
`;

export {
  InnerContainer,
  AboutButtonText,
  AboutButton,
  ReleaseText,
  VersionText,
  Button,
  Container,
  ButtonContainer,
  CloseContainer
};
