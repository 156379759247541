import * as React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import X from '../../../assets/X';
import { TextInput } from '../../components/TextInput/TextInput';
import { client } from '../../config/apollo/client';
import { useStores } from '../../state/stores/Root/RootStoreContext';
import * as MUTATIONS from '../../state/stores/User/User.mutations';
import { TextInputContainer, TextInputInnerContainer, TextInputTitle, Title } from '../Login/style';
import { ErrorText, Spinner, SpinnerContainer } from '../Register/style';
import { ButtonContainer, ButtonText, Container, InputContainer, Label, TitleContainer } from './style';

const ForgotPassword: React.FunctionComponent = props => {
  const [recoverStatus, setRecoverStatus] = React.useState('unset');
  const [emailText, setEmailText] = React.useState('');
  const rootStore = useStores();

  function errorCheckEmail(email: string, setEmailError: React.Dispatch<React.SetStateAction<boolean>>) {
    let validate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/;
    if (validate.test(email) || email === '') {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }

  async function askRecover() {
    setRecoverStatus('pending');
    if (emailText === '') {
      setRecoverStatus('notCompletedField');
    } else {
      try {
        const { data } = (await client.mutate({
          mutation: MUTATIONS.askRecover,
          variables: {
            locale: rootStore.language.language,
            email: emailText
          }
        })) as any;
        if (data.askRecoverMobile) {
          setRecoverStatus('done');
          props.navigation.navigate('Activate', { email: emailText });
        } else {
          setRecoverStatus('error');
        }
      } catch (error) {
        console.log(error);
        setRecoverStatus('error');
      }
    }
  }
  const onPressRecover = () => {
    // props.navigation.navigate('Activate');
    askRecover();
  };

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: '#0466c3' }}
      style={{ flex: 1 }}
      enableOnAndroid
      extraHeight={150}
      enableAutomaticScroll
    >
      <Container>
        <TitleContainer>
          <Title>{rootStore.language.translate.MOBILE.forgotPassword}</Title>
          <TouchableOpacity
            onPress={() => {
              props.navigation.goBack();
            }}
          >
            <X />
          </TouchableOpacity>
        </TitleContainer>
        <Label>{rootStore.language.translate.MOBILE.forgotPasswordTitle}</Label>
        <InputContainer>
          <TextInputContainer activeOpacity={1}>
            <TextInputTitle>{rootStore.language.translate.MOBILE.emailLabel}</TextInputTitle>
            <TextInputInnerContainer>
              <TextInput
                placeholder={rootStore.language.translate.MOBILE.emailPlaceholder}
                errorText="Email is mandatory"
                errorCheckCallback={errorCheckEmail}
                themeErrorColor="#B00020"
                returnKeyType="done"
                onChangeTextCallback={setEmailText}
              />
            </TextInputInnerContainer>
          </TextInputContainer>
        </InputContainer>

        <ButtonContainer disabled={recoverStatus === 'pending'} onPress={onPressRecover}>
          {recoverStatus === 'error' && (
            <ErrorText>{rootStore.language.translate.MOBILE.forgotPasswordError}</ErrorText>
          )}
          {recoverStatus === 'notCompletedField' && (
            <ErrorText>{rootStore.language.translate.MOBILE.uncompleted}</ErrorText>
          )}
          {recoverStatus === 'pending' && (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}
          <ButtonText>{rootStore.language.translate.MOBILE.validate}</ButtonText>
        </ButtonContainer>
      </Container>
    </KeyboardAwareScrollView>
  );
};

export default ForgotPassword;
