import styled from '../../utils/style/styled';

const BannerContainer = styled.View`
  position: absolute;

  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border-radius: 3px;
  width: 85%;
  bottom: 20;
  align-self: center;
  shadow-color: #000;
  shadow-offset: 0px 1px;
  shadow-opacity: 0.1;
  shadow-radius: 2.22px;
`;

const BannerText = styled.Text`
  flex: 1;
  flex-wrap: wrap;
  margin-vertical: 15px;
  color: #8c8c8c;
  font-size: 16px;
`;

const Loading = styled.ActivityIndicator`
  margin: 15px;
`;

export { BannerContainer, BannerText, Loading };
