import { useNetInfo } from '@react-native-community/netinfo';
// import { useCavy } from 'cavy';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { Camera } from 'expo-camera';
import gql from 'graphql-tag';
import { observer } from 'mobx-react-lite';
import React from 'react';
import * as ImagePicker from 'expo-image-picker';
import * as MailComposer from 'expo-mail-composer';
import { Ionicons } from '@expo/vector-icons';
import { Alert, Platform, Pressable, StatusBar, Text, TouchableOpacity, View } from 'react-native';
import SegmentedControlTab from 'react-native-segmented-control-tab';
import { useFocusEffect } from '@react-navigation/native';
import { RUN_TESTS } from '../../../tests/config';
import Banner from '../../components/Banner/Banner';
import { client } from '../../config/apollo/client';
import { useStores } from '../../state/stores/Root/RootStoreContext';
import Scanner from '../Scanner/Scanner';
import { Border, Container, ErrorCamera, Header, StyledCamera, TopContainer } from './style';
import Dialog from 'react-native-dialog';

export const createScan = gql`
  mutation createScan($input: ScanCreateInput!) {
    createScan(input: $input) {
      _id
      client {
        _id
        clientId
        name
        email
      }
      scanDate
      dataMatrixCode
      datamatrixContent {
        productTypeCode
        validityDate
        lotId
        bagID
      }
      productType {
        _id
        name
        picture
        code
        bagSize
      }
      freeBagGrantedDate
      parkedDate
      parkedComment
    }
  }
`;

const Scan: React.FunctionComponent = observer(props => {
  const [selectedIndex, setSelectedIndex] = React.useState(Platform.OS === 'web' ? 1 : 0);
  const [scanned, setScanned] = React.useState(false);
  const [hideCamera, setHideCamera] = React.useState(true);
  const [scannedError, setScannedError] = React.useState(false);
  const [loadingScan, setLoadingScan] = React.useState(false);
  const [scannedData, setScannedData] = React.useState('');
  const [pickedImagePath, setPickedImagePath] = React.useState('');
  const [hasCameraPermission, setCameraPermission] = React.useState<boolean | null>(null);

  const [warningPopupVisible, setWarningPopupVisible] = React.useState<boolean>(false);
  const [bagType, setBagType] = React.useState<string>('');

  const netInfo = useNetInfo();

  const rootStore = useStores();

  // const generateTestHook = useCavy();

  const handleIndexChange = (index: number) => {
    setSelectedIndex(index);
  };
  const getPermissionsAsync = async () => {
    const { status } = await Camera.requestCameraPermissionsAsync();
    const isPermissionGranted = status === 'granted';
    setCameraPermission(isPermissionGranted);
  };

  const openCameraAndSendEmail = async () => {
    // Ask the user for the permission to access the camera
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();

    if (permissionResult.granted === false) {
      return;
    }

    const result = await ImagePicker.launchCameraAsync();

    // Explore the result
    console.log(result);

    const clientId = rootStore?.user.user.clientId;
    const clientIdMessage = clientId ? `My client id is ${clientId} ` : '';

    if (!result.cancelled) {
      setPickedImagePath(result.uri);
      const options = {
        recipients: ['info@virbac.be'],
        subject: '⚠️ Damaged Bag',
        body: `The attached bag ( bag type: ${bagType} ) was damaged. ${clientIdMessage}`,
        attachments: [result.uri]
      };
      const { status } = await MailComposer.composeAsync(options);
    }
    setWarningPopupVisible(false);
    setBagType('');
  };

  const openMailClient = async () => {
    const clientId = rootStore?.user.user.clientId;
    const clientIdMessage = clientId ? `My client id is ${clientId} ` : '';
    const options = {
      recipients: ['info@virbac.be'],
      subject: '⚠️ Damaged Bag',
      body: `The attached bag was damaged. ${clientIdMessage}`
    };
    const { status } = await MailComposer.composeAsync(options);
  };

  React.useEffect(() => {
    Platform.OS !== 'web' && getPermissionsAsync();
  }, []);

  const mutationScan = async (scannedCode: string) => {
    try {
      const { data } = (await client.mutate({
        mutation: createScan,
        variables: {
          input: {
            dataMatrixCode: scannedCode
          }
        },
        refetchQueries: ['getScannedProductTypesInfo']
      })) as any;

      if (data.createScan) {
        setLoadingScan(false);
        setScannedError(false);
        setScannedData(scannedCode);
        // rootStore.products.fetchData();
      } else {
        setLoadingScan(false);
        setScannedError(true);
        setScanned(true);
      }
    } catch (error) {
      console.log(error);
      setLoadingScan(false);
      setScannedError(true);
      setScanned(true);
    }
  };

  const onBarCodeScanned = ({ data }: { data: string }) => {
    if (!scanned && netInfo.isConnected?.toString() === 'true') {
      setLoadingScan(true);
      setScanned(true);
      mutationScan(data);
    }
  };

  const alertPolyfill = (title, description, options, extra) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'));

    if (result) {
      const confirmOption = options.find(({ style }) => style !== 'cancel');
      confirmOption && confirmOption.onPress();
    } else {
      const cancelOption = options.find(({ style }) => style === 'cancel');
      cancelOption && cancelOption.onPress();
    }
  };

  const onPressWarning = () => {
    if (Platform.OS === 'web') {
      console.log('web');
      alertPolyfill(
        'Did you find a damaged bag?',
        'Please specify the type of the bag and attach a picture of it',
        [
          {
            onPress: () => {
              openMailClient();
            },
            style: 'ok'
          },
          {
            onPress: () => {},
            style: 'cancel'
          }
        ],
        null
      );
    } else {
      setWarningPopupVisible(true);
    }
  };

  const renderWarningPopUp = () => {
    return (
      <Dialog.Container contentStyle={{ width: '90%' }} visible={warningPopupVisible}>
        <Dialog.Title>Did you find a damaged bag?</Dialog.Title>
        <Dialog.Description>Please specify the type of the bag and take a picture of it </Dialog.Description>
        <Dialog.Input
          value={bagType}
          onChangeText={text => {
            setBagType(text);
          }}
          placeholder="Type of the bag"
        />
        <Dialog.Button
          label="Cancel"
          onPress={() => {
            setWarningPopupVisible(false);
            setBagType('');
          }}
        />
        <Dialog.Button
          label="OK"
          onPress={openCameraAndSendEmail}
          disabled={bagType === ''}
          color={bagType === '' ? 'gray' : Platform.OS === 'ios' ? '#007ff9' : '#169689'}
        />
      </Dialog.Container>
    );
  };

  function onDismissBanner() {
    setScannedData('');
    setScannedError(false);
    setScanned(false);
  }

  function goToProfileTab() {
    props.navigation.navigate('Profil1e');
  }

  useFocusEffect(
    React.useCallback(() => {
      setHideCamera(true);
      return () => setHideCamera(false);
    }, [])
  );

  return (
    <Container style={{ backgroundColor: '#0466c3' }}>
      <StatusBar barStyle="light-content" />
      <Header />

      <TopContainer style={{ marginTop: Platform.OS === 'android' ? 45 : 20 }}>
        {Platform.OS !== 'web' && (
          <SegmentedControlTab
            values={[rootStore.language.translate.MOBILE.camera, rootStore.language.translate.MOBILE.scanner]}
            selectedIndex={selectedIndex}
            onTabPress={handleIndexChange}
            tabStyle={{ backgroundColor: '#0466c3', borderColor: '#f4f4f4' }}
            tabTextStyle={{ color: '#f4f4f4' }}
            activeTabStyle={{ backgroundColor: '#f4f4f4' }}
            activeTabTextStyle={{ color: '#0466c3' }}
          />
        )}
        {
          <Pressable
            style={{ position: 'absolute', right: -50, top: 3, bottom: 0 }}
            hitSlop={{ top: 3, left: 3, bottom: 3, right: 3 }}
            onPress={onPressWarning}
          >
            <Ionicons name="ios-warning-outline" size={20} color="#fff" />
          </Pressable>
        }
      </TopContainer>

      {Platform.OS !== 'web' && renderWarningPopUp()}

      {RUN_TESTS && (
        <TouchableOpacity
          // ref={generateTestHook('Scan.NavigationButton')}
          goToProfileTab={goToProfileTab}
          style={{ position: 'absolute', opacity: 0, height: 1, width: 1 }}
        ></TouchableOpacity>
      )}
      {selectedIndex === 0 ? (
        hasCameraPermission === false ? (
          <ErrorCamera>
            <Text>{rootStore.language.translate.MOBILE.noCamerAccess}</Text>
          </ErrorCamera>
        ) : (
          <>
            {hideCamera ? (
              <StyledCamera
                // ref={generateTestHook('Scan.Camera')}
                ratio="16:9"
                onBarCodeScanned={onBarCodeScanned}
                barCodeScannerSettings={{
                  barCodeTypes: [BarCodeScanner.Constants.BarCodeType.datamatrix]
                }}
              ></StyledCamera>
            ) : null}
            <Banner
              loadingScan={loadingScan}
              visible={scanned}
              onDismiss={onDismissBanner}
              code={scannedData}
              scannedError={scannedError}
            />
            <Border source={require('../../../assets/camera_scan2.png')} />
          </>
        )
      ) : (
        <Scanner navigation={props.navigation} />
      )}
    </Container>
  );
});

export default Scan;
