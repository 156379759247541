import { Camera } from 'expo-camera';
import { Dimensions } from 'react-native';
import styled from '../../utils/style/styled';
const { width, height } = Dimensions.get('window');

const MainTitle = styled.Text`
  font-family: Verdana;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
`;

const MainSubtitle = styled.Text`
  font-family: Verdana;
  font-size: 14px;
  text-align: center;
  margin-horizontal: 30px;
`;

const MainBottomNumber = styled.Text`
  font-family: Verdana;
  font-weight: bold;
  font-size: 35px;
  text-align: center;
  margin-bottom: 12px;
`;

const MainBottom = styled.Text`
  font-family: Verdana;
  font-size: 16px;
  text-align: center;
`;
const Container = styled.SafeAreaView`
  flex: 1;
`;
const InnerContainer = styled.SafeAreaView`
  flex: 1;
  background-color: #fff;
`;

const Header = styled.View`
  flex: 1;
  margin-vertical: 20px;
  height: 70px;
  width: 100%;
  position: absolute;
  background-color: #0466c3;
  top: 0;
`;

const Border = styled.Image`
  width: ${width - 100}px;
  height: ${width - 100}px;
  position: absolute;
  top: ${(height - 100) / 2 - 50}px;
  align-self: center;
`;

const StyledCamera = styled(Camera)`
  flex: 1;
`;

const ButtonContainer = styled.View`
  flex: 1;
  background-color: #fff;
  justify-content: flex-end;
`;

const TopContainer = styled.View`
  width: 60%;
  margin-vertical: 20px;
  align-self: center;
`;

const MainText = styled.View`
  align-items: center;
`;

const ErrorCamera = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const MainInnerContainer = styled.View`
  flex: 1;
  margin-top: 30px;
`;

const MainData = styled.ScrollView.attrs(props => ({
  contentContainerStyle: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row'
  }
}))`
  margin-top: 30px;
`;

const TextContainer = styled.View`
  margin-horizontal: 10px;
  margin-top: 30px;
`;

const ArrowContainer = styled.View`
  position: absolute;
  right: 20px;
  top: 150px;
`;

const Button = styled.TouchableOpacity`
  align-self: center;
  margin-bottom: 20px;
`;

const ButtonText = styled.Text`
  font-size: 14px;
  color: #5276f7;
  text-decoration-line: underline;
`;

const BottomButton = styled.TouchableOpacity`
  background-color: #0466c3;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-horizontal: 30px;
  margin-bottom: 10px;
`;

const BottomButtonText = styled.Text`
  margin-vertical: 15px;
  color: #fff;
  font-family: Verdana;
  font-weight: bold;
  font-size: 13px;
`;

const AvoidingView = styled.KeyboardAvoidingView`
  flex: 1;
`;

const FakeInput = styled.TextInput`
  opacity: 0;
  position: absolute;
  padding: 2px;
`;

const WarningContainer = styled.View`
  align-self: center;
  margin-top: 30px;
`;

const WarningText = styled.Text`
  font-family: Verdana;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
`;

const MainContainer = styled.View`
  margin-top: 30px;
  flex: 3;
  align-items: center;
  justify-content: space-between;
`;

export {
  WarningContainer,
  MainText,
  AvoidingView,
  FakeInput,
  Button,
  ButtonText,
  BottomButton,
  BottomButtonText,
  Container,
  StyledCamera,
  ButtonContainer,
  Border,
  ErrorCamera,
  MainData,
  ArrowContainer,
  TopContainer,
  Header,
  MainContainer,
  MainInnerContainer,
  InnerContainer,
  TextContainer,
  MainBottom,
  MainBottomNumber,
  MainTitle,
  MainSubtitle,
  WarningText
};
