import { useQuery } from '@apollo/react-hooks';
import * as SecureStore from 'expo-secure-store';
import gql from 'graphql-tag';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ActivityIndicator, Dimensions, FlatList, Platform, StatusBar, StyleSheet, Text } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { BagsLeft } from '../../components/BagsLeft/BagsLeft';
import { useStores } from '../../state/stores/Root/RootStoreContext';
import {
  Container,
  FlatListBackground,
  FreeBag,
  FreeBagContainer,
  FreeBagNumber,
  Header,
  HeaderTitle,
  Image,
  ImageContainer,
  Kg,
  KgContainer,
  KgText,
  RightContainer,
  RowContainer,
  RowInnerContainer,
  Subtitle,
  Title
} from './style';
import AsyncStorage from '@react-native-async-storage/async-storage';
const { height } = Dimensions.get('window');

const getScannedProduct = gql`
  query getScannedProductTypesInfo {
    getScannedProductTypesInfo(page: 1, pageSize: 200) {
      _id
      name
      picture
      code
      bagSize
      unmarkedScansCount
      beginFreeBagScansCount
      # freeBagsToBeGranted
    }
    getScannedProductTypesHistoryInfo(page: 1, pageSize: 200) {
      _id
      name
      picture
      code
      bagSize
      historicalScansCount
    }
  }
`;

const Dashboard: React.FunctionComponent = observer(props => {
  const rootStore = useStores();

  const isFocused = useIsFocused();

  const { loading, error, data, refetch } = useQuery(getScannedProduct);

  React.useEffect(() => {
    if (error && error.message.includes('status code 400')) {
      if (Platform.OS === 'web') {
        AsyncStorage.removeItem('token');
      } else {
        SecureStore.deleteItemAsync('token');
      }
      rootStore.user.set(null);
      // props.navigation.navigate('Login');
    }
  }, [error]);

  React.useEffect(() => {
    if (!loading && !error && data) {
      let newArray = [];

      if (data.getScannedProductTypesInfo.length > 0 && data.getScannedProductTypesHistoryInfo.length > 0) {
        newArray = _.unionBy(data.getScannedProductTypesInfo, data.getScannedProductTypesHistoryInfo, '_id');
      } else if (data.getScannedProductTypesInfo.length > 0 && !(data.getScannedProductTypesHistoryInfo.length > 0)) {
        newArray = data.getScannedProductTypesInfo;
      } else if (!(data.getScannedProductTypesInfo.length > 0) && data.getScannedProductTypesHistoryInfo.length > 0) {
        newArray = data.getScannedProductTypesHistoryInfo;
      }
      rootStore.products.setData(newArray);
    }
  }, [data]);

  React.useEffect(() => {
    if (isFocused === true && !loading && !error && data) {
      refetch();
    }
  }, [isFocused]);

  const renderRow = ({ item, index }) => {
    const bagToBeGranted =
      item.historicalScansCount !== null
        ? Math.floor(item.historicalScansCount / 6)
        : item.unmarkedScansCount >= 6
        ? Math.floor(item.unmarkedScansCount / 6)
        : 0;

    return (
      <RowContainer
        activeOpacity={1}
        onPress={() => {
          props.navigation.navigate('Product', { item });
        }}
        key={item._id}
        noBorder={rootStore.products.data.length - 1 === index}
      >
        <RowInnerContainer>
          <ImageContainer>
            {item.picture ? <Image source={{ uri: item.picture }} resizeMode="contain" resizeMethod="scale" /> : null}
            <KgContainer>
              <KgText>
                {item.bagSize}
                <Kg>{rootStore.language.translate.MOBILE.kg}</Kg>
              </KgText>
            </KgContainer>
          </ImageContainer>
          <RightContainer>
            <Title middle={item.historicalScansCount}>{item.name}</Title>
            {item.historicalScansCount ? null : (
              <>
                <Subtitle>
                  {rootStore.language.translate.MOBILE.freeBag} {Math.abs(item.beginFreeBagScansCount - 6)}{' '}
                  {rootStore.language.translate.MOBILE.scans}
                </Subtitle>
                <BagsLeft number={item.beginFreeBagScansCount} />
              </>
            )}
            <FreeBagContainer>
              <FreeBagNumber number={bagToBeGranted}>{bagToBeGranted} </FreeBagNumber>
              <FreeBag>
                {rootStore.language.translate.MOBILE.freeBagGained
                  ? rootStore.language.translate.MOBILE.freeBagGained
                  : 'sacs gratuits à reçevoir'}
              </FreeBag>
            </FreeBagContainer>
          </RightContainer>
        </RowInnerContainer>
      </RowContainer>
    );
  };

  return (
    <Container>
      <StatusBar barStyle="light-content" />
      <FlatListBackground />
      <Header>
        <HeaderTitle>{rootStore.language.translate.MOBILE.dashboardHeader}</HeaderTitle>
      </Header>

      {loading || error ? (
        <FlatList
          ListEmptyComponent={() => {
            return (
              <Text style={{ textAlign: 'center', fontFamily: 'Verdana', color: 'grey', marginTop: 30 }}>
                {rootStore.language.translate.MOBILE.dashboardListEmpty}
              </Text>
            );
          }}
          ListFooterComponent={() => {
            if (loading) {
              return <ActivityIndicator style={styles.loading} />;
            }

            if (error) {
              return (
                <Text style={{ textAlign: 'center', fontFamily: 'Verdana', color: 'grey', marginTop: 30 }}>
                  {rootStore.language.translate.MOBILE.dashboardListError}
                </Text>
              );
            }
            return null;
          }}
          contentContainerStyle={[styles.flatlist, { flex: rootStore.products.data.length < 4 ? 1 : undefined }]}
          data={rootStore.products.data}
          renderItem={renderRow}
          keyExtractor={item => item._id}
        />
      ) : (
        <FlatList
          ListEmptyComponent={() => {
            return (
              <Text style={{ textAlign: 'center', fontFamily: 'Verdana', color: 'grey', marginTop: 30 }}>
                {'Scan to see the products'}
              </Text>
            );
          }}
          contentContainerStyle={[styles.flatlist, { flex: rootStore.products.data.length < 4 ? 1 : undefined }]}
          data={rootStore.products.data}
          renderItem={renderRow}
          keyExtractor={item => item._id}
        />
      )}
    </Container>
  );
});

const styles = StyleSheet.create({
  flatlist: {
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    marginTop: height > 800 ? 85 : 65,
    paddingBottom: height > 800 ? 85 : 65,
    backgroundColor: '#fff',
    alignSelf: 'center',
    minWidth: Platform.OS === 'web' ? '50%' : '100%'
  },
  loading: { marginTop: 20 }
});

export default Dashboard;
