import { useQuery } from '@apollo/react-hooks';
import { useNetInfo } from '@react-native-community/netinfo';
// import { useCavy } from 'cavy';
import Constants from 'expo-constants';
import * as SecureStore from 'expo-secure-store';
import gql from 'graphql-tag';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMutation } from 'react-apollo';
import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  Platform,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View
} from 'react-native';
import Modal from 'react-native-modal';
import { useIsFocused } from '@react-navigation/native';
import SearchSVG from '../../../assets/SearchSVG';
import X from '../../../assets/X';
import ButtonComponent from '../../components/Button/Button';
import { DisabledInput } from '../../components/DisabledInput/DisabledInput';
import Input from '../../components/Input/Input';
import ServerNotice from '../../components/ServerNotice/ServerNotice';
import { useStores } from '../../state/stores/Root/RootStoreContext';
import { FlatListBackground, Header, HeaderTitle } from '../Dashboard/style';
import { ButtonText } from '../Login/style';
import { Picker, PickerContainer, PickerText, PickerTitle, PickerTop, SimpleText, Spinner } from '../Register/style';
import {
  AboutButton,
  AboutButtonText,
  Button,
  ButtonContainer,
  CloseContainer,
  Container,
  InnerContainer,
  ReleaseText,
  VersionText
} from './style';
import AsyncStorage from '@react-native-async-storage/async-storage';
const { height } = Dimensions.get('window');
const appMetadata = require('../../../../app.json');

export const UPDATE_CLIENT = gql`
  mutation updateClient($input: ClientUpdateInput!) {
    updateClient(input: $input) {
      _id
      wholesaler {
        _id
        name
      }
    }
  }
`;

const getMyProfileQuery = gql`
  query getMyProfile {
    getMyProfile {
      _id
      email
      client {
        _id
        clientId
        name
        email
        wholesaler {
          _id
          name
        }
        salesRep {
          _id
          name
        }
      }
    }
  }
`;

const Settings: React.FunctionComponent = observer(props => {
  const wholesalerInputRef = React.useRef<React.ReactElement>(null);
  const languageInputRef = React.useRef<React.ReactElement>(null);
  const [modal, setModal] = React.useState(false);
  const [languageModal, setLanguageModal] = React.useState(false);
  const [loadingRefetch, setLoadingRefetch] = React.useState(false);
  const [data, setData] = React.useState({ _id: null, name: 'Grossiste' });
  const [
    updateClient,
    { data: clientUpdateData, loading: clientUpdateLoading, error: clientUpdateError }
  ] = useMutation(UPDATE_CLIENT);

  const isFocused = useIsFocused();
  const netInfo = useNetInfo();
  const rootStore = useStores();

  const { data: userData, loading: userDataLoading, error: userDataError, refetch: userDataRefetch } = useQuery(
    getMyProfileQuery
  );

  // const generateTestHook = useCavy();

  React.useEffect(() => {
    if (!userDataLoading && !userDataError && userData) {
      setData({ _id: null, name: 'Grossiste' });
    }
  }, [userData]);

  React.useEffect(() => {
    if (isFocused === true) {
      userDataRefetch().catch(err => {
        console.log(err);
      });
    }
    if (isFocused === false) {
      setData({ _id: null, name: 'Grossiste' });
    }
  }, [isFocused]);

  const onPressLogout = async () => {
    if (Platform.OS === 'web') {
      await AsyncStorage.removeItem('token');
    } else {
      await SecureStore.deleteItemAsync('token');
    }

    setData({ _id: null, name: 'Grossiste' });
    // props.navigation.navigate('Login');
    rootStore.user.set(null);
  };

  const renderPickerItem = ({ item }) => {
    return (
      <TouchableOpacity
        key={item._id}
        onPress={() => {
          setData({ _id: item._id, name: item.name });
          setModal(false);
        }}
      >
        <PickerText style={styles.pickerText}>{item.name}</PickerText>
      </TouchableOpacity>
    );
  };
  const renderPickerLanguage = ({ item }) => {
    return (
      <TouchableOpacity
        key={item._id}
        onPress={() => {
          rootStore.language.setLanguage(item.key);
          setLanguageModal(false);
        }}
      >
        <PickerText style={styles.pickerText}>{item.name}</PickerText>
      </TouchableOpacity>
    );
  };

  const renderEmptyComponent = () => {
    return (
      <View style={styles.emptyContainer}>
        <Text style={styles.loading}>{rootStore.language.translate.MOBILE.loading}</Text>
        <Spinner color="grey" />
      </View>
    );
  };

  const onPressSave = () => {
    // Refetch query me with the latest data
    userDataRefetch()
      .then(({ data: { getMyProfile } }) => {
        const input = {
          _id: getMyProfile.client._id,
          clientId: getMyProfile.client.clientId,
          name: getMyProfile.client.name,
          email: getMyProfile.client.email,
          salesRep: getMyProfile.client.salesRep._id,
          wholesaler: data._id
        };
        updateClient({
          variables: { input },
          refetchQueries: ['getMyProfile']
        })
          .then(data => {
            console.log(data);
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Container>
      <StatusBar barStyle="light-content" />

      <FlatListBackground />
      <Header>
        <HeaderTitle>{rootStore.language.translate.MOBILE.myProfile.toUpperCase()}</HeaderTitle>
      </Header>

      <InnerContainer contentContainerStyle={styles.scrollviewContainer}>
        <DisabledInput
          label={rootStore.language.translate.MOBILE.clientIdLabel}
          value={!userDataLoading && !userDataError && userData ? userData.getMyProfile?.client?.clientId : 'Loading'}
        />
        <DisabledInput
          label={rootStore.language.translate.MOBILE.yourRepresentant}
          value={!userDataLoading && !userDataError && userData ? userData.getMyProfile?.client?.name : 'Loading'}
        />
        <DisabledInput
          label={rootStore.language.translate.MOBILE.emailLabel}
          value={!userDataLoading && !userDataError && userData ? userData.getMyProfile.email : 'Loading'}
        />

        <Button
          onPress={() => {
            props.navigation.navigate('ChangeEmail');
          }}
        >
          <ButtonText>{rootStore.language.translate.MOBILE.changeEmail}</ButtonText>
        </Button>

        <TouchableWithoutFeedback
          onPress={() => {
            rootStore.wholesaler.fetchData();
            setModal(true);
          }}
        >
          <PickerContainer pointerEvents="box-only" style={styles.pickerCont}>
            <Input
              editable={false}
              title={rootStore.language.translate.MOBILE.wholesalerLabel}
              reference={wholesalerInputRef}
              placeholder={rootStore.language.translate.MOBILE.wholesalerPlaceholder}
              value={
                data._id === null
                  ? !userDataLoading && !userDataError && userData
                    ? userData.getMyProfile?.client?.wholesaler?.name
                    : 'Loading...'
                  : data.name
                  ? data.name
                  : ''
              }
              errorText={rootStore.language.translate.MOBILE.passwordError}
              themeColor="grey"
              themeErrorColor="#B00020"
              returnKeyType="done"
              dropDown
            />
          </PickerContainer>
        </TouchableWithoutFeedback>

        <TouchableWithoutFeedback
          onPress={() => {
            setLanguageModal(true);
          }}
        >
          <PickerContainer pointerEvents="box-only" style={styles.pickerContainer}>
            <Input
              editable={false}
              title={rootStore.language.translate.MOBILE.languageLabel}
              reference={languageInputRef}
              placeholder={rootStore.language.translate.MOBILE.languagePlaceholder}
              value={rootStore.language.string}
              returnKeyType="done"
              dropDown
            />
          </PickerContainer>
        </TouchableWithoutFeedback>

        <AboutButton
          onPress={() => {
            props.navigation.navigate('AboutUs');
          }}
        >
          <SearchSVG fill="grey" />
          <AboutButtonText>{rootStore.language.translate.MOBILE.aboutVirbac}</AboutButtonText>
        </AboutButton>

        <ButtonContainer>
          <ButtonComponent
            title={rootStore.language.translate.MOBILE.save}
            onPress={onPressSave}
            loading={clientUpdateLoading}
            disabled={data._id === null || clientUpdateLoading === true || netInfo.isConnected?.toString() === 'false'}
          />
          <SimpleText>{rootStore.language.translate.MOBILE.changeAccount}</SimpleText>
          <TouchableOpacity onPress={onPressLogout}>
            <ButtonText>{rootStore.language.translate.MOBILE.logout}</ButtonText>
          </TouchableOpacity>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 10,
              width: '90%',
              justifyContent: 'space-between'
            }}
          >
            <ReleaseText>rc-v{appMetadata['expo']['version']}</ReleaseText>
            <VersionText>{appMetadata['aksAppVersion']}</VersionText>
          </View>
        </ButtonContainer>
        <Modal
          isVisible={languageModal}
          useNativeDriver={true}
          style={styles.modal}
          hideModalContentWhileAnimating={true}
          onCancelPressed={() => setLanguageModal(false)}
          onBackButtonPressed={() => setLanguageModal(false)}
          onModalHide={() => {
            setLanguageModal(false);
          }}
          onBackdropPress={() => {
            setLanguageModal(false);
          }}
        >
          <StatusBar hidden />

          <Picker>
            <PickerTop style={styles.pickerTop}>
              <PickerTitle>{rootStore.language.translate.MOBILE.pickLanguage}</PickerTitle>
              <CloseContainer
                onPress={() => {
                  setLanguageModal(false);
                }}
                hitSlop={{ top: 10, right: 10, left: 10, bottom: 10 }}
              >
                <X />
              </CloseContainer>
            </PickerTop>
            <FlatList
              keyExtractor={item => item._id}
              renderItem={renderPickerLanguage}
              data={rootStore.language.list}
              contentContainerStyle={styles.flatlistContainer}
              ListEmptyComponent={renderEmptyComponent}
            />
          </Picker>
        </Modal>

        <Modal
          isVisible={modal}
          useNativeDriver={true}
          style={styles.modal}
          hideModalContentWhileAnimating={true}
          onCancelPressed={() => setModal(false)}
          onBackButtonPressed={() => setModal(false)}
          onModalHide={() => {
            setModal(false);
          }}
          onBackdropPress={() => {
            setModal(false);
          }}
        >
          <StatusBar hidden />

          <Picker>
            <PickerTop style={styles.pickerTop}>
              <PickerTitle>{rootStore.language.translate.MOBILE.pickWholesaler}</PickerTitle>
              <CloseContainer
                onPress={() => {
                  setModal(false);
                }}
                hitSlop={{ top: 10, right: 10, left: 10, bottom: 10 }}
              >
                <X />
              </CloseContainer>
            </PickerTop>
            <FlatList
              keyExtractor={item => item._id}
              renderItem={renderPickerItem}
              data={rootStore.wholesaler.data}
              contentContainerStyle={styles.flatlistContainer}
              ListEmptyComponent={renderEmptyComponent}
            />
          </Picker>
        </Modal>
        {userDataError && (
          <TouchableOpacity
            style={{
              backgroundColor: '#0466c3',
              padding: 10,
              position: 'absolute',
              top: 10,
              alignSelf: 'center',
              borderRadius: 20,
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
              elevation: 5
            }}
            onPress={() => {
              setLoadingRefetch(true);
              userDataRefetch()
                .then(() => {
                  setLoadingRefetch(false);
                })
                .catch(err => {
                  setLoadingRefetch(false);
                  console.log(err);
                });
            }}
          >
            {loadingRefetch ? <ActivityIndicator /> : <Text style={{ color: '#fff' }}>Incorrect Data. Retry</Text>}
          </TouchableOpacity>
        )}
        {userDataError && <ServerNotice />}
      </InnerContainer>
    </Container>
  );
});

const styles = StyleSheet.create({
  scrollviewContainer: {
    marginTop: height > 800 ? 85 : 65,
    paddingBottom: height > 800 ? 85 : 65,
    backgroundColor: '#fff',
    paddingHorizontal: 30,
    paddingTop: 30,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    alignSelf: Platform.OS === 'web' ? 'center' : 'auto',
    minWidth: Platform.OS === 'web' ? '50%' : '100%'
  },
  pickerTop: {
    flexDirection: 'row',
    marginTop: 10,
    paddingBottom: 10,
    justifyContent: 'center',
    borderBottomColor: '#898989',
    borderBottomWidth: 1.33
  },
  pickerCont: { paddingTop: 0 },
  pickerContainer: { paddingTop: 0, marginBottom: 20 },
  pickerText: { marginVertical: 20 },
  emptyContainer: { minHeight: 300 },
  loading: { textAlign: 'center', marginTop: 30, marginBottom: 10 },
  activityIndicator: { marginBottom: 30 },
  flatlistContainer: { paddingBottom: 20 },
  modal: { margin: 0 }
});

export default Settings;
