import { types } from 'mobx-state-tree';

export const Product: any = types.model('Product', {
  _id: types.identifier,
  __typename: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  picture: types.maybeNull(types.string),
  code: types.string,
  // TODO: change to string
  bagSize: types.union(types.string, types.number),
  unmarkedScansCount: types.maybeNull(types.number),
  beginFreeBagScansCount: types.maybeNull(types.number),
  historicalScansCount: types.maybeNull(types.number)
});

export const ProductsModel: any = types
  .model('ProductsModel', {
    identifier: types.optional(types.identifier, 'ProductsModel'),
    data: types.optional(types.array(Product), []),
    state: types.maybe(types.enumeration(['pending', 'done', 'error']))
  })
  .actions(self => {
    const setData = data => {
      self.data = data;
    };
    return {
      setData
    };
  });
