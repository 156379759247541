import React, { useEffect, useState } from 'react';
import { View, Text, Dimensions, StyleSheet } from 'react-native';
import NetInfo, { useNetInfo } from '@react-native-community/netinfo';
const { width } = Dimensions.get('window');
function MiniOfflineSign() {
  return (
    <View style={styles.offlineContainer} pointerEvents="none">
      <Text style={styles.offlineText}>No Internet Connection</Text>
    </View>
  );
}

const OfflineNotice = () => {
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(state => {
      setIsConnected(state.isConnected);
    });

    return () => unsubscribe();
  }, []);

  return isConnected ? null : <MiniOfflineSign />;
};

const styles = StyleSheet.create({
  offlineContainer: {
    backgroundColor: '#b52424',
    height: 55,
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'row',
    width,
    position: 'absolute',
    top: 0
  },
  offlineText: {
    color: '#fff'
  }
});
export default OfflineNotice;
