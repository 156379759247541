import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import ListIcon from '../../../assets/ListSVG';
import ScanIcon from '../../../assets/ScanSVG';
import UserIcon from '../../../assets/UserSVG';
import { DashboardStack } from './Dashboard';
import { MainStack } from './MainStack';
import { ProfileStack } from './ProfileStack';

const Tab = createBottomTabNavigator();

export function MainTabNavigator() {
  return (
    <Tab.Navigator screenOptions={{ headerShown: false }} initialRouteName="Scan">
      <Tab.Screen
        name="Dashboard"
        component={DashboardStack}
        options={{
          tabBarIcon: ({ focused }) => <ListIcon focused={focused} />,
          tabBarActiveTintColor: '#004D97',
          tabBarInactiveBackgroundColor: '#F8F8F8',
          tabBarActiveBackgroundColor: '#F8F8F8',
          tabBarStyle: { backgroundColor: '#F8F8F8' }
        }}
      />
      <Tab.Screen
        name="Scan"
        component={MainStack}
        options={{
          tabBarIcon: ({ focused }) => <ScanIcon focused={focused} />,
          tabBarActiveTintColor: '#004D97',
          tabBarInactiveBackgroundColor: '#F8F8F8',
          tabBarActiveBackgroundColor: '#F8F8F8',
          tabBarStyle: { backgroundColor: '#F8F8F8' }
        }}
      />
      <Tab.Screen
        name="Profile"
        component={ProfileStack}
        options={{
          tabBarIcon: ({ focused }) => <UserIcon focused={focused} />,
          tabBarActiveTintColor: '#004D97',
          tabBarInactiveBackgroundColor: '#F8F8F8',
          tabBarActiveBackgroundColor: '#F8F8F8',
          tabBarStyle: { backgroundColor: '#F8F8F8' }
        }}
      />
    </Tab.Navigator>
  );
}
