import React from 'react';
import Scan from '../Scan/Scan';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

const Stack = createNativeStackNavigator();

export const MainStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      <Stack.Screen name="Scan" component={Scan} />
    </Stack.Navigator>
  );
};
