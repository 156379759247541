import styled from '../../utils/style/styled';

const ButtonContainer = styled.TouchableOpacity`
  align-items: center;
  background-color: ${props => (props.inverted ? '#fff' : '#0466c3')};
  border-width: 1.3px;
  border-color: #0466c3;
  border-radius: 50px;
  margin-horizontal: 30px;
  width: 100%;
  shadow-color: #000;
  shadow-offset: 0px 1px;
  shadow-opacity: 0.1;
  shadow-radius: 2.22px;
  margin-top: 15px;
`;

const ButtonText = styled.Text`
  margin-vertical: 15px;
  font-family: Verdana;
  font-weight: bold;
  color: ${props => (props.inverted ? '#0466c3' : '#fff')};
  font-size: 13px;
`;

const Loading = styled.ActivityIndicator`
  position: absolute;
  right: 40px;
  align-self: center;
  top: 0;
  bottom: 0;
`;

export { ButtonContainer, ButtonText, Loading };
