import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import { Dimensions, Modal, TouchableOpacity, TouchableWithoutFeedback, View, SectionList } from 'react-native';
import X from '../../../assets/X';
import { BagsLeft } from '../../components/BagsLeft/BagsLeft';
import { useStores } from '../../state/stores/Root/RootStoreContext';
import {
  ImageContainer,
  Kg,
  KgContainer,
  KgText,
  RightContainer,
  RowContainer,
  RowInnerContainer,
  Subtitle,
  Title
} from '../Dashboard/style';
import {
  CloseContainer,
  FullImage,
  Image,
  Loading,
  RowTextLeft,
  RowTextRight,
  SafeView,
  SectionContainer,
  SectionHeaderText,
  SectionRow,
  SectionTitle,
  TextError
} from './style';

const { width, height } = Dimensions.get('window');

const productHistory = gql`
  query getProductFreebagHistory($productId: String!) {
    getProductFreeBagHistory(productId: $productId, pageSize: 10, page: 1) {
      freeBagGrantedDate
      freeBagsGrantedOrDue
      moduloScansFreeBag
    }
  }
`;

const Product: React.FunctionComponent = props => {
  const [showFullImage, setShowFullImage] = React.useState(false);

  const rootStore = useStores();

  const { loading, error, data, refetch } = useQuery(productHistory, {
    variables: { productId: props.route?.params?.item._id }
  });

  React.useEffect(() => {
    refetch();
  }, []);

  const renderData = data => {
    const sectionData = [
      {
        title: rootStore.language.translate.MOBILE.inProgressValidation
          ? rootStore.language.translate.MOBILE.inProgressValidation
          : 'En cours de validation',
        data: data.filter(item => item.freeBagGrantedDate === null)
      },
      {
        title: rootStore.language.translate.MOBILE.orderWholesaler
          ? rootStore.language.translate.MOBILE.orderWholesaler
          : 'Commandes chez votre grossiste',
        data: data.filter(item => item.freeBagGrantedDate !== null)
      }
    ];

    return sectionData;
  };

  const renderSection = ({ item }) => (
    <SectionContainer>
      <SectionTitle>
        {item.freeBagGrantedDate
          ? moment(item.freeBagGrantedDate)
              .format('D MMM, YYYY')
              .replace(',', '')
              .toUpperCase()
          : rootStore.language.translate.MOBILE.inProgress
          ? rootStore.language.translate.MOBILE.inProgress
          : 'EN COURS'}
      </SectionTitle>
      <SectionRow>
        <RowTextLeft>{item.freeBagsGrantedOrDue}</RowTextLeft>
        <RowTextRight>
          {rootStore.language.translate.MOBILE.freeBagDetail
            ? rootStore.language.translate.MOBILE.freeBagDetail
            : 'sacs\ngratuis'}
        </RowTextRight>
      </SectionRow>
    </SectionContainer>
  );

  const renderSectionHeader = ({ section: { title } }) => <SectionHeaderText>{title}</SectionHeaderText>;

  return (
    <SafeView>
      <CloseContainer
        onPress={() => {
          props.navigation.goBack();
        }}
        hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
      >
        <View>
          <X />
        </View>
      </CloseContainer>
      <RowContainer activeOpacity={1} noBorder>
        <RowInnerContainer>
          <ImageContainer>
            <TouchableOpacity
              activeOpacity={0.7}
              onPress={() => {
                setShowFullImage(true);
              }}
            >
              <Image source={{ uri: props.route?.params?.item.picture }} resizeMode="contain" resizeMethod="scale" />
            </TouchableOpacity>

            <KgContainer>
              <KgText>
                {props.route?.params?.item.bagSize}
                <Kg>{rootStore.language.translate.MOBILE.kg}</Kg>
              </KgText>
            </KgContainer>
          </ImageContainer>
          <RightContainer>
            <Title>{props.route?.params?.item.name}</Title>
            <Subtitle>
              {rootStore.language.translate.MOBILE.freeBag}{' '}
              {Math.abs(props.route?.params?.item.beginFreeBagScansCount - 6)}{' '}
              {rootStore.language.translate.MOBILE.scans}
            </Subtitle>
            <BagsLeft number={props.route?.params?.item.beginFreeBagScansCount} />
          </RightContainer>
        </RowInnerContainer>
      </RowContainer>

      {error ? (
        <TextError>{rootStore.language.translate.MOBILE.productDetailError}</TextError>
      ) : loading || !data ? (
        <Loading />
      ) : (
        <SectionList
          sections={renderData(data.getProductFreeBagHistory)}
          keyExtractor={(item, index) => item + index}
          renderItem={renderSection}
          renderSectionHeader={renderSectionHeader}
        />
      )}

      <Modal visible={showFullImage} transparent={true} animationType="fade">
        <TouchableWithoutFeedback
          onPress={() => {
            setShowFullImage(false);
          }}
          style={{ justifyContent: 'center', alignItems: 'center', width, height }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#fff'
            }}
          >
            <FullImage source={{ uri: props.route?.params?.item.picture }} resizeMode="contain" resizeMethod="scale" />
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </SafeView>
  );
};

export default Product;
