import React from 'react';
import Activate from '../Activate/Activate';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import Login from '../Login/Login';
import Register from '../Register/Register';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

const Stack = createNativeStackNavigator();

export const LoginStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      <Stack.Screen name="Login" component={Login} />
      <Stack.Screen name="Register" component={Register} />
      <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
      <Stack.Screen name="Activate" component={Activate} />
    </Stack.Navigator>
  );
};
