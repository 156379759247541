import { Feather } from '@expo/vector-icons';
import * as React from 'react';
import { Dimensions, Linking, Platform, StatusBar, StyleSheet, View } from 'react-native';
import X from '../../../assets/X';
import { useStores } from '../../state/stores/Root/RootStoreContext';
import { FlatListBackground, Header, HeaderTitle } from '../Dashboard/style';
import { InnerContainer } from '../ProfileTab/style';
import { BottomButtons, Button, ButtonText, CloseContainer, Container, Label, Title, SafeView } from './style';

const { width, height } = Dimensions.get('window');

const AboutUs: React.FunctionComponent = props => {
  const rootStore = useStores();

  return (
    <Container>
      <StatusBar barStyle="light-content" />
      <FlatListBackground />
      <Header>
        {/* {rootStore.language.translate.MOBILE.aboutUs.toUpperCase()} */}
        <HeaderTitle>A PROPOS DE NOUS</HeaderTitle>
      </Header>

      <InnerContainer contentContainerStyle={styles.scrollviewContainer}>
        {/* {rootStore.language.translate.MOBILE.aboutUsTitle} */}
        <Title>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Title>

        {/* {rootStore.language.translate.MOBILE.aboutUsText} */}
        <Label style={{ flex: 1 }} numberOfLines={30} ellipsizeMode="tail">
          Suspendisse vitae sagittis nulla. Curabitur faucibus, eros ut viverra gravida, tortor arcu laoreet lectus, in
          ultricies nunc quam a eros. Suspendisse potenti. Pellentesque habitant morbi tristique senectus et netus et
          malesuada fames ac turpis egestas. Praesent porttitor nisl in fringilla dictum. Nam nec dolor et est pretium
          egestas eget vehicula ex. Sed eleifend enim turpis, id efficitur erat tincidunt nec. Vivamus ipsum elit,
          convallis nec dui vel, porta consequat arcu. Vestibulum gravida, enim eget pretium scelerisque, nibh elit
          sagittis dui, in sollicitudin enim lacus sit amet dui. Aenean condimentum, ipsum vitae auctor semper, augue
          metus luctus mi, a pretium massa velit in eros. Aenean pulvinar ultrices egestas. Ut tincidunt vestibulum
          aliquet. Convallis nec dui vel, porta consequat arcu. Vestibulum gravida, enim eget pretium scelerisque, nibh
          elit sagittis dui, in sollicitudin enim lacus sit amet dui. Aenean condimentum, ipsum vitae auctor semper,
          augue metus luctus mi, a pretium massa velit in eros. Aenean pulvinar ultrices egestas. Ut tincidunt
          vestibulum aliquet. arcu. Vestibulum gravida, enim eget pretium scelerisque, nibh elit sagittis dui, in
          sollicitudin enim lacus sit amet dui. Aenean condimentum, ipsum vitae auctor semper, augue metus luctus mi, a
          pretium massa velit in eros. Aenean pulvinar ultrices egestas. Ut tincidunt vestibulum aliquet. Convallis nec
          dui vel, porta consequat arcu. Vestibulum gravida, enim eget pretium scelerisque, nibh elit sagittis dui, in
          sollicitudin enim lacus sit amet dui. Aenean condimentum, ipsum vitae auctor semper, augue metus luctus mi, a
          pretium massa velit in eros. Aenean pulvinar ultrices egestas. Ut tincidunt vestibulum aliquet. aliquet. arcu.
          Vestibulum gravida, enim eget pretium scelerisque, nibh elit sagittis dui, in sollicitudin enim lacus sit amet
          dui. Aenean condimentum, ipsum vitae auctor semper, augue metus luctus mi, a pretium massa velit in eros.
          Aenean pulvinar ultrices egestas. Ut tincidunt vestibulum aliquet. Convallis nec dui vel, porta consequat
          arcu. Vestibulum gravida, enim eget pretium scelerisque, nibh elit sagittis dui, in sollicitudin enim lacus
          sit amet dui. Aenean condimentum, ipsum vitae auctor semper, augue metus luctus mi, a pretium massa velit in
          eros. Aenean pulvinar ultrices egestas. Ut tincidunt vestibulum aliquet.
        </Label>

        <BottomButtons>
          <Button
            onPress={() => {
              Linking.openURL('tel:003216387260');
            }}
          >
            <Feather size={30} color="grey" name="phone-call" />
            <ButtonText>+3216387260</ButtonText>
          </Button>

          <Button
            onPress={() => {
              Linking.openURL('mailto:Info@virbac.be');
            }}
          >
            <Feather size={30} color="grey" name="mail" />
            <ButtonText>Info@virbac.be</ButtonText>
          </Button>
        </BottomButtons>
      </InnerContainer>
      <CloseContainer
        onPress={() => {
          props.navigation.goBack();
        }}
        hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
      >
        <X fill="#fff" />
      </CloseContainer>
    </Container>
  );
};

const styles = StyleSheet.create({
  scrollviewContainer: {
    flex: 1,
    marginTop: height > 800 ? 85 : 65,
    paddingBottom: height > 800 ? 85 : 65,
    backgroundColor: '#fff',
    paddingHorizontal: 30,
    paddingTop: 30,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    maxWidth: Platform.OS === 'web' ? '50%' : '100%',
    alignSelf: 'center'
  }
});

export default AboutUs;
