import gql from 'graphql-tag';

export const fields = gql`
  fragment fields on User {
    _id
    email
    name
    firstName
    lastName
    password
    activationCode
    active
    role {
      _id
      name
      class
    }
    profileImage
  }
`;

export const register = gql`
  mutation register($email: String!, $password: String!, $wholesaler: String!, $clientId: String!) {
    registerClientUser(email: $email, password: $password, wholesaler: $wholesaler, clientId: $clientId)
  }
`;

export const login = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export const activate = gql`
  mutation activate($email: String!, $code: String!, $password: String!) {
    recover(email: $email, code: $code, password: $string)
  }
`;

export const askRecover = gql`
  mutation askRecover($email: String!, $locale: String!) {
    askRecoverMobile(email: $email, locale: $locale)
  }
`;

export const recover = gql`
  mutation recover($email: String!, $code: String!, $password: String!) {
    recover(email: $email, code: $code, password: $password)
  }
`;

export const updateToken = gql`
  mutation updateToken($input: UserUpdateInput!) {
    updateToken(input: $input)
  }
`;

export const resendCode = gql`
  mutation resendCode($email: String!, $returnLink: String!) {
    resendCode(email: $email, returnLink: $returnLink)
  }
`;
