import React from 'react';
import Svg, { Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

const X = props => (
  <Svg width={20} height={20} {...props}>
    <Path
      d="M11.769 10l7.865-7.865A1.25 1.25 0 1017.865.366L10 8.232 2.135.366a1.25 1.25 0 10-1.769 1.77L8.232 10 .366 17.865a1.25 1.25 0 101.769 1.769L10 11.768l7.865 7.866a1.247 1.247 0 001.769 0 1.25 1.25 0 000-1.77L11.769 10z"
      fill={props.fill ? props.fill : '#5A5A5B'}
      fillRule="nonzero"
    />
  </Svg>
);

export default X;
