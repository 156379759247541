import { Dimensions } from 'react-native';
import styled from '../../utils/style/styled';
const { width, height } = Dimensions.get('window');

const MainBottomNumber = styled.Text`
  font-family: Verdana;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  margin-bottom: 5px;
  color: #414142;
`;

const MainBottom = styled.Text`
  font-family: Verdana;
  font-size: 16px;
  text-align: center;
  color: #8c8c8c;
`;
const MainContainer = styled.SafeAreaView`
  flex: 1;
  margin-top: 20px;
  background-color: #fff;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
`;

const MainTextContainer = styled.SafeAreaView`
  width: ${width}px;
  height: ${height}px;
  align-items: center;
  justify-content: center;
`;

const AnimatedContainer = styled.View`
  flex: 1;
  margin-top: -20px;
`;

const RowContainer = styled.View`
  flex-direction: row;
  align-self: center;
`;

const NoScanContainer = styled.View`
  margin-top: 10px;
`;

const QrContainer = styled.View`
  margin-top: 10px;
`;

const ScanningContainer = styled.View`
  margin-top: -15px;
`;

const MainText = styled.View`
  flex: 1;
  margin-top: 15px;
  align-items: center;
  margin-horizontal: 30px;
`;

const MainTitle = styled.Text`
  font-family: Verdana;
  font-weight: bold;
  font-size: 18px;
  color: #414142;
  margin-bottom: 20px;
  text-align: center;
`;

const MainSubtitle = styled.Text`
  font-family: Verdana;
  font-size: 14px;
  text-align: center;
  color: #8c8c8c;
`;

const BottomButton = styled.TouchableOpacity<{ offline?: boolean }>`
  position: absolute;
  align-items: center;
  background-color: ${props => (props.offline ? '#414142' : '#0466c3')};
  justify-content: center;
  width: ${width - 60}px;
  bottom: 5px;
  right: 30px;
  left: 30px;
  align-self: center;
  border-radius: 30px;
  shadow-offset: 0px 2px;
  shadow-color: #000;
  shadow-opacity: 0.23;
  shadow-radius: 2.62px;
  elevation: 4;
`;

const BottomButtonModal = styled.TouchableOpacity`
  align-items: center;
  background-color: #0466c3;
  justify-content: center;
  width: ${width - 60}px;
  align-self: center;
  border-radius: 30px;
  shadow-offset: 0px 2px;
  shadow-color: #000;
  shadow-opacity: 0.23;
  shadow-radius: 2.62px;
  elevation: 4;
`;

const BottomButtonText = styled.Text`
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  padding-vertical: 16px;
`;

const AvoidingView = styled.KeyboardAvoidingView`
  flex: 1;
`;

const FakeInput = styled.TextInput`
  opacity: 0;
  position: absolute;
  left: 0;
  width: ${width}px;
  padding: 2px;
`;

const Loading = styled.ActivityIndicator`
  position: absolute;
  top: -30px;
  align-self: center;
`;

export {
  AvoidingView,
  RowContainer,
  BottomButtonText,
  BottomButtonModal,
  MainContainer,
  ScanningContainer,
  MainTextContainer,
  QrContainer,
  BottomButton,
  FakeInput,
  AnimatedContainer,
  NoScanContainer,
  Loading,
  MainBottom,
  MainBottomNumber,
  MainSubtitle,
  MainText,
  MainTitle
};
