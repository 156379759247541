import { observer } from 'mobx-react-lite';
import * as React from 'react';
import IconDown from '../../../assets/IconDown';
import { TextInput } from '../TextInput/TextInput';
import { TextInputContainer, TextInputInnerContainer, TextInputTitle } from './style';

export interface InputProps {}

const Input: React.FunctionComponent<InputProps> = observer(props => {
  return (
    <TextInputContainer activeOpacity={1} noMarginTop={props.noMarginTop}>
      <TextInputTitle>{props.title}</TextInputTitle>
      <TextInputInnerContainer>
        <TextInput reference={props.reference} {...props} />
        {props.dropDown && <IconDown />}
      </TextInputInnerContainer>
    </TextInputContainer>
  );
});

export default Input;
