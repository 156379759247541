import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

const ListIcon = props => (
  <Svg width={31} height={27} style={{ transform: [{ scale: 0.7 }] }} {...props}>
    <G fill={props.focused === true ? '#004D97' : '#A09E9E'} fillRule="nonzero">
      <Path d="M11.53 4.807l18.286.02a.963.963 0 000-1.925l-18.286-.02a.963.963 0 000 1.925zM29.835 12.516l-18.286-.019a.963.963 0 000 1.925l18.286.02a.963.963 0 000-1.926zM29.835 22.14l-18.286-.019a.963.963 0 000 1.925l18.286.02a.963.963 0 000-1.925zM3.84.024a3.84 3.84 0 100 7.68 3.84 3.84 0 000-7.68zM3.83 5.79c-1.081 0-1.948-.843-1.948-1.925S2.749 1.94 3.83 1.94c1.082 0 1.925.843 1.925 1.925A1.901 1.901 0 013.83 5.79zM3.84 9.648a3.84 3.84 0 100 7.68 3.84 3.84 0 000-7.68zm-.01 5.765c-1.081 0-1.948-.843-1.948-1.925 0-1.081.867-1.924 1.948-1.924 1.082 0 1.925.843 1.925 1.924a1.902 1.902 0 01-1.925 1.925zM3.84 19.253a3.84 3.84 0 100 7.68 3.84 3.84 0 000-7.68zm-.01 5.765c-1.081 0-1.948-.843-1.948-1.925 0-1.081.867-1.925 1.948-1.925 1.082 0 1.925.844 1.925 1.925a1.901 1.901 0 01-1.925 1.925z" />
    </G>
  </Svg>
);

export default ListIcon;
