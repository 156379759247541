import styled from '../../utils/style/styled';

const Bags = styled.View`
  flex-direction: row;
  margin-top: 7px;
`;

const Bag = styled.View`
  width: 30px;
  height: 5px;
  margin-right: 3px;
  border-radius: 2px;
`;

export { Bags, Bag };
